<script lang="ts">
  import { ref, watch } from 'vue';
  import { useAppStore } from '@src/store/app';
  import { EmojiManager } from '@utils/chat/emoji-manager';

  export default {
    props: {
      items: {
        type: Array as () => Emoji[],
        required: true,
      },
      query: {
        type: String,
        required: true,
      },
      command: {
        type: Function,
        required: true,
      },
    },
    setup(props) {
      const inlinePicker = ref<HTMLDivElement | null>(null);
      const appStore = useAppStore();
      const emojiManager = EmojiManager.getInstance();

      const selectedIndex = ref(0);

      const onKeyDown = ({ event }: { event: any }) => {
        if (event.key === 'ArrowUp') {
          upHandler();
          return true;
        }

        if (event.key === 'ArrowDown') {
          downHandler();
          return true;
        }

        if (event.key === 'Enter' || event.key === 'Tab') {
          enterHandler();
          return true;
        }

        return false;
      };

      watch(selectedIndex, (index) => {
        //Scroll to top
        if (index >= props.items.length) {
          inlinePicker.value?.scrollTo(0, inlinePicker.value.scrollHeight);
        }
        //Scroll to bottom
        else if (index <= 0) {
          inlinePicker.value?.scrollTo(0, 0);
        }
        //Scroll to element in list
        else {
          document.getElementById(`emoji-index-${index}`)?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
          });
        }
      });

      const upHandler = () => {
        selectedIndex.value = (selectedIndex.value + props.items.length - 1) % props.items.length;
      };

      const downHandler = () => {
        selectedIndex.value = (selectedIndex.value + 1) % props.items.length;
      };

      const enterHandler = () => {
        selectItem(selectedIndex.value);
      };

      const setIndex = (index: number) => {
        selectedIndex.value = index;
      };

      const selectItem = (index: number) => {
        const emoji = props.items[index];

        //i dont know why this would happen but it did
        // https://myoutdesk.sentry.io/issues/5999757210/?alert_rule_id=14480732&alert_timestamp=1729197145995&alert_type=email&environment=production&notification_uuid=98c32e4b-b6c5-49c1-9f5c-115be6516396&project=4505354254548992&referrer=alert_email
        if (!emoji) {
          return;
        }

        const skin_variation_from_base = appStore.settings.emojiSkinTone;

        if (!emoji.skin_variations || !skin_variation_from_base) {
          appStore.updateRecentlyUsedEmojis(emoji.unified);
          return props.command({ emoji: emoji });
        }

        if (emoji.skin_variations && emoji.skin_variations[skin_variation_from_base]) {
          emoji.unified = emoji.skin_variations[skin_variation_from_base].unified;
        }

        appStore.updateRecentlyUsedEmojis(emoji.unified);

        return props.command({ emoji: emoji });
      };

      return {
        inlinePicker,
        onKeyDown,
        upHandler,
        downHandler,
        enterHandler,
        setIndex,
        selectItem,
        selectedIndex,
        emojiManager,
      };
    },
  };
</script>

<template>
  <div v-if="items.length" @contextmenu.stop.prevent>
    <div ref="inlinePicker" class="w-full rounded border bg-white p-2 shadow dark:bg-surface-800 dark:border-black max-h-96 overflow-auto small-scroll">
      <div class="p-1 text-xs font-bold uppercase text-surface-500 dark:text-surface-400">Emoji Matching {{ query }}</div>
      <button v-for="(emoji, index) in items" :id="`emoji-index-${index}`" :key="`emoji-${emoji.name}-${index}`" :class="[index === selectedIndex ? 'dark:bg-surface-700 bg-surface-100' : '']" class="flex w-full items-center gap-2 rounded p-2" @mouseenter="setIndex(index)" @click="selectItem(index)">
        <div class="relative inline-emoji-picker">
          <div class="emojiSprite-32" :style="emojiManager.getBackgroundPosition(emoji.unified, 32)"></div>
        </div>
        <div class="text-sm text-surface-700 dark:text-surface-100">:{{ emoji.short_name }}:</div>
      </button>
    </div>
  </div>
</template>
