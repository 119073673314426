import { createApp } from 'vue';
import { MTI_MODULES } from '@modules/.';
import { initializeThemeControls } from '@src/bootstrap/theme-controls';
import { useRouter } from 'vue-router';
import heartbeatService from '@src/services/heartbeat-service';
import App from './App.vue';
import 'primeicons/primeicons.css';
import './style.css';

/**
 * Create router and application
 */
console.log('[boot] creating app');
const app = createApp(App);
console.log('[boot] registering all modules and services');
MTI_MODULES().forEach((module) => module.onVueAppInit?.(app));
console.log('[boot] mounting');
app.mount('#app');

// TODO: should this really go here? We need to start it, but im not sure if this is the best place
// TODO: find a better point to register all services and put them into a DI container
const router = useRouter();
const heartbeat = heartbeatService.getInstance(router);

initializeThemeControls();
