<script setup lang="ts">
  import { computed, onBeforeMount, onMounted, onUnmounted, ref, toRefs } from 'vue';
  import { DateTime } from 'luxon';
  import injectStrict from '@utils/vue/utils';
  import { MarkdownServiceKey } from '@utils/vue/symbols';
  import { useChatStore } from '@src/store/chat';
  import MessageOptions from '@components/Chat/MessageOptions.vue';
  import { useConfirm } from 'primevue/useconfirm';
  import { Message } from '@pubnub/chat';
  import ChatReaction from '@components/Chat/ChatReaction.vue';
  import ChatMediaContainer from '@components/Chat/ChatMediaContainer.vue';
  import ChatParentMessage from '@components/Chat/ChatParentMessage.vue';
  import { useAppStore } from '@src/store/app';
  import { EmojiManager } from '@utils/chat/emoji-manager';
  import { Logger } from '@utils/logger';
  import { writeImage } from '@tauri-apps/plugin-clipboard-manager';
  import { IconMenuItem, Menu, MenuItem, PredefinedMenuItem } from '@tauri-apps/api/menu';
  import { KnownChatUser } from '@src/types/ChatAndMessaging';

  import menuEditIcon from '@src/assets/images/menuIcons/Edit.png';
  import menuReplyIcon from '@src/assets/images/menuIcons/Reply.png';
  import menuCopyIcon from '@src/assets/images/menuIcons/Copy.png';
  import menuCopyImageIcon from '@src/assets/images/menuIcons/Photo.png';
  import menuDeleteIcon from '@src/assets/images/menuIcons/Delete.png';
  import menuPinIcon from '@src/assets/images/menuIcons/Pin.png';
  import menuUnpinIcon from '@src/assets/images/menuIcons/Unpin.png';
  import menuReloadIcon from '@src/assets/images/menuIcons/Reload.png';
  import menuEditIconWhite from '@src/assets/images/menuIcons/EditWhite.png';
  import menuReplyIconWhite from '@src/assets/images/menuIcons/ReplyWhite.png';
  import menuCopyIconWhite from '@src/assets/images/menuIcons/CopyWhite.png';
  import menuDeleteIconWhite from '@src/assets/images/menuIcons/DeleteWhite.png';
  import menuCopyImageIconWhite from '@src/assets/images/menuIcons/PhotoWhite.png';
  import menuPinIconWhite from '@src/assets/images/menuIcons/PinWhite.png';
  import menuUnpinIconWhite from '@src/assets/images/menuIcons/UnpinWhite.png';
  import menuReloadIconWhite from '@src/assets/images/menuIcons/ReloadWhite.png';

  import { open } from '@tauri-apps/plugin-shell';
  import { calculateTooltipPosition } from '@utils/chat/tooltip-calculator';
  import useHelpersSpace from '@use/helpers';

  const { getInitials } = useHelpersSpace();
  const log = new Logger('ChatMessage');

  const emojiManager = EmojiManager.getInstance();
  const confirm = useConfirm();

  const props = defineProps<{
    message: Message;
    reactions: boolean;
    senderAvatar: string | undefined | null;
    parentMessage?: { message: Message; avatar: string | null } | null;
    parentUser?: KnownChatUser;
  }>();

  const appStore = useAppStore();

  const { message } = toRefs(props);
  const tooltipRef = ref();
  const showTooltip = ref(false);
  const avatarTooltip = ref();
  const showAvatarTooltip = ref(false);
  const content = ref<HTMLParagraphElement | null>(null);
  const chatStore = useChatStore();
  const markdownService = injectStrict(MarkdownServiceKey);
  const me = ref<HTMLDivElement | null>(null);
  const observer = ref<ResizeObserver | null>(null);
  const hasCodeBlocks = ref(false);
  const hoveringMessage = ref(false);

  const artificialNodesCreated = ref<HTMLElement[]>([]);
  const showEmojiPickerButton = ref(false);

  const emit = defineEmits(['will-delete', 'scroll-to-message', 'send-message']);

  const relativeCreatedTime = computed(() => {
    const messageDate = DateTime.fromMillis(parseInt(message.value.timetoken) / 10000);
    const now = DateTime.now();
    if (now.diff(messageDate, 'days').days > 1) {
      // show it as a date string (July 1st, 7:00AM)
      return messageDate.toLocaleString(DateTime.DATETIME_MED).replace(`, ${now.year}`, '');
    }
    return DateTime.fromMillis(parseInt(message.value.timetoken) / 10000).toLocaleString(DateTime.TIME_SIMPLE);
  });

  async function imageToArrayBuffer(url: string): Promise<ArrayBuffer> {
    const response = await fetch(url);
    const blob = await response.blob();
    return await blob.arrayBuffer();
  }

  function unicodeToEmoji(unicode: string) {
    const codePoints = unicode.split('-').map((u) => parseInt(u, 16));
    return String.fromCodePoint(...codePoints);
  }

  const applyReaction = async (emojiUnicode: string) => {
    let emoji = emojiManager.getEmojiMap().get(emojiUnicode);
    if (!emoji) {
      log.error('Emoji not found');
      return;
    }

    const skinTonePreference = appStore.settings.emojiSkinTone;
    if (emoji.skin_variations && skinTonePreference && emoji.skin_variations[skinTonePreference]) {
      emoji.unified = emoji.skin_variations[skinTonePreference].unified;
    }

    appStore.updateRecentlyUsedEmojis(emoji.unified);
    await chatStore.applyReactionToMessage(message.value.timetoken, unicodeToEmoji(emoji.unified));
  };

  //@ts-ignore
  const mute = async (timeout: number) => {
    await chatStore.muteUserWhoPostedMessage(message.value, timeout);
  };

  const isOwnMessage = computed(() => {
    return message.value.userId === chatStore.chatUserId;
  });

  const isChannelOperator = computed(() => {
    return chatStore.canOperateOnCurrentChannel;
  });

  const getMessageDate = computed(() => {
    let now = DateTime.now();
    let createdAt = DateTime.fromMillis(parseInt(message.value.timetoken) / 10000);
    //see if created at and now are the same day
    if (createdAt.hasSame(now, 'day')) {
      return 'Today at ' + createdAt.toLocaleString(DateTime.TIME_SIMPLE);
    }
    return createdAt.toLocaleString(DateTime.DATETIME_SHORT);
  });

  const copyCodeButtonListener = (e: MouseEvent) => {
    if (!e || !e.target || !(e.target instanceof HTMLElement)) {
      return;
    }
    const targetElement = e.target as HTMLElement;
    const nearestCodeBlock = e.target.closest('pre')?.querySelector('code');
    navigator.clipboard.writeText(nearestCodeBlock?.textContent || '').then(() => {
      targetElement.innerHTML = '<svg fill="currentColor" class="size-4" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M19.916 4.626a.75.75 0 0 1 .208 1.04l-9 13.5a.75.75 0 0 1-1.154.114l-6-6a.75.75 0 0 1 1.06-1.06l5.353 5.353 8.493-12.74a.75.75 0 0 1 1.04-.207Z" clip-rule="evenodd"/></svg>';
      setTimeout(() => {
        targetElement.innerHTML =
          '<svg fill="currentColor" class="size-4" viewBox="0 0 24 24"><path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 0 1 3.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0 1 21 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 0 1 7.5 16.125V3.375Z"/><path d="M15 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 17.25 7.5h-1.875A.375.375 0 0 1 15 7.125V5.25ZM4.875 6H6v10.125A3.375 3.375 0 0 0 9.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V7.875C3 6.839 3.84 6 4.875 6Z"/></svg>';
      }, 2000);
    });
  };

  /**
   * Creates the code support node for the code block (language information, copy button support)
   *
   * @param codeBlock - The code block element
   */
  //@ts-ignore
  const createCodeSupportNode = (codeBlock: HTMLElement) => {
    try {
      const preBlock = codeBlock.parentElement;
      if (!preBlock) {
        console.warn('No parent element found for the code block.');
        return;
      }
      preBlock.classList.add('relative', 'block', 'group');

      const wrapperNode = document.createElement('div');
      wrapperNode.classList.add('right-8', 'top-4', 'absolute');

      const copyButton = document.createElement('button');
      copyButton.classList.add('hidden', 'group-hover:block');
      copyButton.innerHTML =
        '<svg fill="currentColor" class="size-4" viewBox="0 0 24 24"><path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 0 1 3.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0 1 21 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 0 1 7.5 16.125V3.375Z"/><path d="M15 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 17.25 7.5h-1.875A.375.375 0 0 1 15 7.125V5.25ZM4.875 6H6v10.125A3.375 3.375 0 0 0 9.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V7.875C3 6.839 3.84 6 4.875 6Z"/></svg>';
      copyButton.addEventListener('click', copyCodeButtonListener);
      wrapperNode.appendChild(copyButton);
      preBlock.appendChild(wrapperNode);
      artificialNodesCreated.value.push(copyButton);
      artificialNodesCreated.value.push(wrapperNode);
    } catch (error) {
      console.error('Error creating code support node:', error);
    }
  };

  const linkHandler = (e: any) => {
    e.preventDefault();
    const url = e.target.href;
    if (url) {
      open(url);
    }
  };

  onMounted(() => {
    // bind to any mentions in the message
    // const mentions = content?.value?.querySelectorAll('.mention');
    // mentions?.forEach((mention) => {});

    // Add code block supporting elements
    // TODO this doesn't work with inline code blocks ore code blocks that are only one line the copy button is off screen
    // const codeBlocks = content?.value?.querySelectorAll('code');
    // codeBlocks?.forEach((codeBlock) => {
    //   createCodeSupportNode(codeBlock as HTMLElement);
    // });
    // prevent default link navigation behavior
    const availableLinks = content?.value?.querySelectorAll('a');
    availableLinks?.forEach((link) => {
      link.addEventListener('click', linkHandler);
    });
  });

  const sendMessageToReplyUserFromToolTip = ({ message }: { message: string }) => {
    if (!props.parentUser) {
      return;
    }

    emit('send-message', { user: props.parentUser, message });
    closeTooltip();
  };

  const handleScrollToMessage = ({ message }: { message: Message }) => {
    emit('scroll-to-message', { message });
  };

  const closeTooltip = () => {
    chatStore.composerFocusStealOverride = false;
    if (tooltipRef.value) {
      // tooltipRef.value.tippy.hide();
      showAvatarTooltip.value = false;
    }
    if (avatarTooltip.value) {
      // avatarTooltip.value.hide();
      showTooltip.value = false;
    }
  };

  onBeforeMount(() => {
    hasCodeBlocks.value = message.value.text.includes('```');
  });

  onUnmounted(() => {
    observer.value?.disconnect();
    /**
     * Cleanup our nodes, click handlers, event listeners, etc.
     */
    const availableLinks = content?.value?.querySelectorAll('a');
    availableLinks?.forEach((link) => {
      link.removeEventListener('click', linkHandler);
    });
    artificialNodesCreated.value.forEach((node) => {
      node.removeEventListener('click', copyCodeButtonListener);
      node.remove();
    });
  });

  const showUserTooltip = (event: MouseEvent, user?: KnownChatUser) => {
    //Since these share the same tooltip, we need to close the message context menu if it's open
    chatStore.closeAllOpenMenus();
    /**
     * requestAnimationFrame is used to avoid a race condition when opening a new tooltip while another is still open.
     * Without this, the old tooltip might close but the new one may not display. This ensures the old tooltip is fully
     * closed before opening the new one.
     */

    requestAnimationFrame(() => {
      if (!user) {
        return;
      }

      chatStore.participantCardUser = user;
      chatStore.composerFocusStealOverride = true;
      chatStore.participantCardOpen = true;

      /**
       * We request an additional animation frame to ensure the tooltip is in the DOM,
       * allowing us to accurately retrieve its dimensions.
       */

      requestAnimationFrame(() => {
        let success = calculateTooltipPosition(event, 'chat-layer', 'participant-card');

        // If positioning the tooltip fails for any reason, close it and reset the values.
        if (!success) {
          chatStore.participantCardUser = null;
          chatStore.composerFocusStealOverride = false;
          chatStore.participantCardOpen = false;
        }
      });
    });
  };

  const messageText = computed(() => {
    const messageEdits = props.message.actions?.['edited'];
    if (!messageEdits) return props.message.content.text || '';

    const flatEdits = Object.entries(messageEdits)
      .map(([k, v]) => ({ value: k, ...v[0] }))
      .filter((v) => v && v.actionTimetoken);

    if (flatEdits.length === 0) {
      return props.message.content.text || '';
    }
    const lastEdit = flatEdits.reduce((a, b) => (a.actionTimetoken > b.actionTimetoken ? a : b));

    return lastEdit.value;
  });

  const canUnpin = computed(() => {
    return chatStore.currentChannelPinnedTimetokens.includes(message.value.timetoken);
  });

  const canPin = computed(() => {
    return chatStore.currentChannelUrl.startsWith('direct.') || chatStore.currentChannelUrl.startsWith('group.');
  });

  const pinMessage = () => {
    chatStore.pinMessage(message.value.timetoken);
  };

  const unpinMessage = () => {
    chatStore.removePin(message.value.timetoken);
  };

  const editMessage = () => {
    chatStore.startEditingMessage(message.value.timetoken);
  };

  const replyMessage = () => {
    chatStore.replyToMessage(message.value.timetoken);
  };

  const copyImageToClipboard = async () => {
    if (!chatStore.contextMenuImage) {
      return;
    }

    const fetchOptions: RequestInit = {};

    if (import.meta.env.MODE !== 'development') {
      fetchOptions.credentials = 'include';
    }

    return fetch(chatStore.contextMenuImage.src, fetchOptions)
      .then((response) => response.arrayBuffer())
      .then((buffer) => {
        writeImage(buffer);
      })
      .catch((error) => console.error('Failed to fetch and write image:', error));
  };

  const deleteMessage = () => {
    confirm.require({
      message: 'Are you sure you want to delete this message?',
      header: 'Delete message?',
      blockScroll: true,
      rejectLabel: 'Cancel',
      acceptLabel: 'Delete',
      group: 'message-delete',
      accept: () => {
        chatStore.deleteMessage(message.value.timetoken);
      },
    });
  };

  const copyText = () => {
    navigator.clipboard.writeText(message.value.text);
  };

  const reload = () => {
    window.location.href = `${window.location.pathname}?timestamp=${new Date().getTime()}`;
  };

  const showMessageContextMenu = async (event: MouseEvent, user?: KnownChatUser) => {
    if (!user) {
      return;
    }

    let isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

    let target: HTMLImageElement | null = event.target as HTMLImageElement;
    if (!target || !target.tagName || target.tagName !== 'IMG') {
      chatStore.contextMenuImage = null;
    }

    let menuItems: Promise<MenuItem | PredefinedMenuItem>[] = [];
    if (isOwnMessage.value) {
      let editIcon = await imageToArrayBuffer(isDark ? menuEditIconWhite : menuEditIcon);

      menuItems.push(
        IconMenuItem.new({
          text: 'Edit Message',
          icon: editIcon,
          action: () => {
            editMessage();
          },
        })
      );
    }

    if (canPin.value && !canUnpin.value) {
      let pinIcon = await imageToArrayBuffer(isDark ? menuPinIconWhite : menuPinIcon);
      menuItems.push(
        IconMenuItem.new({
          text: 'Pin Message',
          icon: pinIcon,
          action: () => {
            pinMessage();
          },
        })
      );
    }

    if (canUnpin.value) {
      let unpinIcon = await imageToArrayBuffer(isDark ? menuUnpinIconWhite : menuUnpinIcon);
      menuItems.push(
        IconMenuItem.new({
          text: 'Unpin Message',
          icon: unpinIcon,
          action: () => {
            unpinMessage();
          },
        })
      );
    }

    let replyIcon = await imageToArrayBuffer(isDark ? menuReplyIconWhite : menuReplyIcon);

    menuItems.push(
      IconMenuItem.new({
        text: 'Reply',
        icon: replyIcon,
        action: () => {
          replyMessage();
        },
      })
    );

    menuItems.push(PredefinedMenuItem.new({ item: 'Separator' }));

    if (chatStore.contextMenuImage) {
      let copyImageIcon = await imageToArrayBuffer(isDark ? menuCopyImageIconWhite : menuCopyImageIcon);
      menuItems.push(
        IconMenuItem.new({
          text: 'Copy Image',
          icon: copyImageIcon,
          action: async () => {
            await copyImageToClipboard();
          },
        })
      );
    }

    let copyIcon = await imageToArrayBuffer(isDark ? menuCopyIconWhite : menuCopyIcon);

    menuItems.push(
      IconMenuItem.new({
        text: 'Copy Message',
        icon: copyIcon,
        action: () => {
          copyText();
        },
      })
    );

    if (isOwnMessage.value || isChannelOperator.value) {
      let deleteIcon = await imageToArrayBuffer(isDark ? menuDeleteIconWhite : menuDeleteIcon);
      menuItems.push(
        IconMenuItem.new({
          text: 'Delete Message',
          icon: deleteIcon,
          action: () => {
            deleteMessage();
          },
        })
      );
    }

    menuItems.push(PredefinedMenuItem.new({ item: 'Separator' }));
    let reloadIcon = await imageToArrayBuffer(isDark ? menuReloadIconWhite : menuReloadIcon);
    menuItems.push(
      IconMenuItem.new({
        text: 'Reload',
        icon: reloadIcon,
        action: () => {
          reload();
        },
      })
    );

    let items = await Promise.all(menuItems);

    const menu = await Menu.new({
      items: items,
    });

    await menu.popup();
  };
</script>

<template>
  <div ref="me" class="px-4 group/chat-message relative" @contextmenu.prevent="showMessageContextMenu($event, chatStore.knownUsersByUuid.get(message.userId))">
    <ChatParentMessage v-if="parentMessage" :parent-message="parentMessage" :parent-user="parentUser" @scroll-to-message="handleScrollToMessage" @send-message="sendMessageToReplyUserFromToolTip" />
    <div v-if="!message.deleted" class="flex relative" @mouseenter="hoveringMessage = true" @mouseleave="hoveringMessage = false">
      <MessageOptions
        v-show="message.meta?.customType !== 'admin' && hoveringMessage"
        class="absolute -top-8 -right-0.5"
        :message="message"
        :timetoken="message.timetoken"
        :reactions="reactions"
        :is-own-message="isOwnMessage"
        :sender-id="message.userId"
        @simple-react="applyReaction"
        @show-message-context-menu="showMessageContextMenu($event, chatStore.knownUsersByUuid.get(message.userId))"
      />
      <div>
        <button class="mr-2 shrink-0 w-12" @click="showUserTooltip($event, chatStore.knownUsersByUuid.get(message.userId))">
          <div v-if="!senderAvatar" class="size-10 rounded-full object-cover pointer-events-none flex items-center bg-primary-100 dark:bg-primary-700 dark:text-surface-100 justify-center text-surface-900 text-sm tracking-tighter font-semibold">
            {{ getInitials(chatStore.knownUsersByUuid.get(message.userId)?.name) }}
          </div>
          <img v-else :src="senderAvatar" alt="profile" class="rounded-full size-10 object-cover pointer-events-none" draggable="false" />
        </button>
      </div>

      <div class="overflow-hidden w-full">
        <!-- sender information -->
        <div class="flex flex-wrap lg:flex-nowrap gap-x-2 items-baseline">
          <div class="">
            <button class="font-bold text-sm text-surface-900 dark:text-surface-100 max-w-48 truncate hover:underline" @click="showUserTooltip($event, chatStore.knownUsersByUuid.get(message.userId))">
              {{ chatStore.knownUsersByUuid.get(message.userId)?.name }}
            </button>
          </div>
          <!-- show time Today at HH:MM A or M/DD/YYYY, HH:MM A -->
          <p class="text-[11px] text-surface-500 dark:text-surface-400 select-text shrink-0 font-semibold">
            {{ getMessageDate }}
          </p>
        </div>
        <!-- body -->
        <div class="message">
          <p v-if="message.meta?.customType === 'admin'">
            <span class="text-xs text-surface-500 dark:text-surface-400">{{ relativeCreatedTime }}</span>
            <span class="text-xs text-surface-500 dark:text-surface-400">{{ messageText }}</span>
          </p>
          <!--eslint-disable-next-line vue/no-v-html-->
          <div v-if="messageText" v-once ref="content" class="select-text w-full pr-[4.275rem] mb-2" v-html="markdownService.render(messageText)" />
          <ChatMediaContainer v-if="!message.deleted && message.meta?.mytimeinFiles?.length" :files="message.meta.mytimeinFiles" :meta="message.meta" />
          <ChatReaction v-if="!message.deleted" :message="message" :current-user-id="chatStore.currentUserId as string" :show-emoji-picker-button="showEmojiPickerButton" @react="applyReaction" @mouseenter="showEmojiPickerButton = true" @mouseleave="showEmojiPickerButton = false" />
        </div>
      </div>
    </div>
  </div>
</template>
