import { createRouter, createWebHashHistory, RouteLocationNormalized } from 'vue-router';
import { useAppStore } from '@src/store/app';
import { useAlertStore } from '@src/store/alert';
import ChatEmptyView from './views/ChatEmptyView.vue';
import { useChatStore } from '@src/store/chat';

const routes = [
  { name: 'root', path: '/', component: () => import('./views/Startup.vue') },
  {
    name: 'login',
    path: '/login',
    component: () => import('./views/Login.vue'),
  },
  {
    name: 'forgot-password',
    path: '/forgot',
    component: () => import('./views/ForgotPassword.vue'),
  },
  {
    name: 'dashboard',
    path: '/dashboard',
    component: () => import('./views/Dashboard.vue'),
  },
  {
    name: 'requests',
    path: '/requests',
    component: () => import('./views/RequestCenter.vue'),
  },
  {
    name: 'pay-periods',
    path: '/pay',
    component: () => import('./views/PaySchedule.vue'),
  },
  {
    name: 'bug',
    path: '/bug',
    component: () => import('./views/BugReport.vue'),
  },
  {
    name: 'activity',
    path: '/activity',
    component: () => import('./views/Activity.vue'),
  },
  {
    name: 'keybinds',
    path: '/keybinds',
    component: () => import('./views/Keybinds.vue'),
  },
  { name: 'gpt', path: '/gpt', component: () => import('./views/ModGpt.vue') },
  {
    name: 'settings',
    path: '/settings',
    component: () => import('./views/Settings.vue'),
  },
  {
    name: 'version-cleaner',
    path: '/versionCleaner',
    component: () => import('./views/VersionCleaner.vue'),
  },
  {
    name: 'diagnostics',
    path: '/diagnostics',
    component: () => import('./views/Diagnostics.vue'),
  },
  {
    name: 'licenses',
    path: '/licenses',
    component: () => import('./views/Licenses.vue'),
  },
  { name: 'chat', path: '/chat', component: ChatEmptyView }, // not lazy loaded, keep this view hot
  { name: 'news', path: '/news', component: () => import('./views/News.vue') },
  // { path: '/:pathMatch(.*)*', redirect: '/login' }
];

export function makeRouter() {
  const applicationState = useAppStore();
  const alerts = useAlertStore();
  const router = createRouter({
    routes,
    history: createWebHashHistory(),
  });

  const hideShowSentryFeedback = (to: RouteLocationNormalized) => {
    if (to.name === 'chat' || to.name === 'gpt') {
      const sentryFeedback = document.getElementById('sentry-feedback');
      if (sentryFeedback) {
        sentryFeedback.classList.add('hidden');
      }
    }

    if (to.name !== 'chat' && to.name !== 'gpt') {
      const sentryFeedback = document.getElementById('sentry-feedback');
      if (sentryFeedback) {
        sentryFeedback.classList.remove('hidden');
      }
    }
  };

  router.beforeEach((to) => {
    alerts.clearAll();
    if (!applicationState.currentUser && to.name !== 'login' && to.name !== 'root') {
      return { name: 'root' };
    }
    if (to.name === 'chat') {
      const chatStore = useChatStore();
      chatStore.firstDraw = true;
    }
    hideShowSentryFeedback(to);
  });

  return router;
}

export default {
  makeRouter,
};
