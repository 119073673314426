<script setup lang="ts">
  import { computed, ref } from 'vue';
  import { useChatStore } from '@src/store/chat';
  import CatsWelcome from '@components/svgs/CatsWelcome.vue';
  import ChatParticipant from '@components/Chat/ChatParticipant.vue';
  import VirtualScroller from 'primevue/virtualscroller';
  import { KnownChatUser } from '@src/types/ChatAndMessaging';

  defineProps<{
    open: boolean;
  }>();

  const emit = defineEmits(['toggle', 'send-message']);

  const chatStore = useChatStore();
  const selectedUser = ref<KnownChatUser>();

  const filteredParticipants = computed((): KnownChatUser[] => {
    if (['announcements', 'modcon_2024'].includes(chatStore.currentChannelUrl)) {
      return [];
    }
    if (!chatStore.currentChannelParticipants || chatStore.currentChannelParticipants.size === 0 || !chatStore.currentChannelUsers || chatStore.currentChannelUsers.length === 0 || !Array.isArray(chatStore.currentChannelUsers)) {
      return [];
    }
    const operators = chatStore.operators.get(chatStore.currentChannelUrl) ?? [];
    const operatorsAsKnownUsers: KnownChatUser[] = operators ? (operators.map((id) => chatStore.knownUsersByUuid.get(id)).filter(Boolean) as KnownChatUser[]) : [];
    const orderedChannelUsers = (chatStore.currentChannelUsers || [])
      .filter((u) => !operatorsAsKnownUsers.includes(u))
      .toSorted((a, b) => {
        return a.name.localeCompare(b.name);
      });

    return [...operatorsAsKnownUsers, ...orderedChannelUsers];
  });

  const isSpecialChannel = computed(() => {
    return ['announcements', 'modcon_2024'].includes(chatStore.currentChannelUrl);
  });

  const isChannelOperator = computed(() => {
    return chatStore.canOperateOnCurrentChannel;
  });

  const sendMessage = ({ user, message }: { user: KnownChatUser; message: string }) => {
    selectedUser.value = user;
    emit('send-message', { user, message });
  };
</script>

<template>
  <div class="bg-surface-50 dark:bg-surface-800/80 h-full p-2 group" @contextmenu.stop.prevent="chatStore.closeAllOpenMenus()">
    <div v-if="open" class="relative h-full flex flex-col">
      <div v-if="!isSpecialChannel && isChannelOperator">
        <div class="relative">
          <img :src="chatStore.currentChannelRaw?.custom?.cover as string" class="rounded-full h-16 w-16 object-cover cursor-pointer" alt="" />
        </div>
      </div>
      <h2 v-if="isSpecialChannel" class="mb-2 flex-initial font-bold text-surface-600 dark:text-surface-300 p-2 text-xs uppercase">Event Channel</h2>
      <h2 v-else class="mb-2 flex-initial font-bold text-surface-600 dark:text-surface-300 p-2 text-xs uppercase">Members - {{ chatStore.currentChannelParticipants.size }}</h2>
      <div v-if="isSpecialChannel">
        <CatsWelcome class="w-full h-32" />
        <p v-if="chatStore.currentChannelRaw?.description" class="text-center text-sm text-surface-700 dark:text-surface-300">
          {{ chatStore.currentChannelRaw?.description }}
        </p>
      </div>
      <!-- DONT REMOVE HEIGHT, ITS THE MAGIC SAUCE -->
      <!-- Look I removed it don't yell at me we changed the layout so this should happen but i left i here cause magic-->
      <!--      <div class="flex-1" style="height: 100px">-->
      <VirtualScroller v-else :items="filteredParticipants" :item-size="50" class="small-scroll grow">
        <template #item="{ item }">
          <ChatParticipant :key="item.id" :user="item" class="mb-1" @send-message="sendMessage" />
        </template>
      </VirtualScroller>
      <!--      </div>-->
    </div>
  </div>
</template>
