<script setup lang="ts">
  import Message from 'primevue/message';

  const messages = {
    OFFLINE_TIME: {
      title: 'Excessive offline time',
      message: 'Our records show excessive offline time for your last shift. Per MyOutDesk policy, the tracker must be running at all times while working for your client. Please ensure the tracker remains open for the full duration of your shift.',
      buttonText: 'I Understand',
    },
    INACTIVITY_TIME: {
      title: 'Excessive inactivity time',
      message: 'Your account has registered extended periods of inactivity. Consistent engagement is essential to maintain effective communication and client satisfaction. Please review your recent activity and take steps to ensure regular interaction.',
      buttonText: 'I Understand',
    },
    SYSTEM_CLOCK_OUT: {
      title: 'Failure to clock out',
      //add a message for failure to clock out similar to above
      message: 'It looks like you didn’t clock out for your last shift. Please remember to clock out at the end of your workday to ensure accurate time tracking.',
      buttonText: 'I Understand',
    },
  };

  const props = defineProps<{
    warning: LastShiftWarning;
  }>();

  const emit = defineEmits(['clear']);
</script>

<template>
  <div>
    <Message v-if="messages[props.warning.type]" severity="error">
      <h2 class="mb-2">{{ messages[props.warning.type]?.title }}</h2>
      <p class="text-xs">{{ messages[props.warning.type]?.message }}</p>
    </Message>
  </div>
</template>
