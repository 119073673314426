<script setup lang="ts">
  import { useChatStore } from '@src/store/chat';

  const { messageOptionTooltipMessage } = useChatStore();
</script>

<template>
  <div v-if="messageOptionTooltipMessage" class="bg-white px-2 py-1 shadow-md dark:bg-surface-950 dark:border-transparent dark:shadow-none max-w-64 rounded-md">
    <div v-emoji class="text-xs">{{ messageOptionTooltipMessage }}</div>
    <!-- Arrow below -->
    <div class="absolute -translate-x-1/2 w-0 h-0 border-t-surface-0 dark:border-t-surface-950 -bottom-[8px] border-l-transparent border-r-transparent border-[8px] border-b-0 left-1/2"></div>
  </div>
</template>
