<script setup lang="ts">
  import { computed, onMounted } from 'vue';
  import { useChatStore } from '@src/store/chat';
  import { calculateTooltipPosition } from '@utils/chat/tooltip-calculator';
  import { KnownChatUser } from '@src/types/ChatAndMessaging';

  const props = defineProps<{
    user: KnownChatUser;
  }>();

  const emits = defineEmits(['send-message']);
  const chatStore = useChatStore();

  const getInitials = (name: string | undefined) => {
    if (!name) return '';
    return name
      .split(' ')
      .map((word) => word[0])
      .join('')
      .slice(0, 2);
  };

  //TODO WHY IS THIS HERE????????
  const connectivityHandler = () => {
    // we are offline, disconnect from chat temporarily
    if (!navigator.onLine) {
      console.log('CONNECTION DOWN - DISCONNECTING FROM CHAT');
      chatStore.disconnect();
    } else {
      console.log('CONNECTION UP, INITIALIZING CHAT');
      chatStore.reinitializeChat();
    }
  };

  const isOnline = computed(() => {
    return chatStore.knownUsersByUuid.get(props.user.id)?.status === 'ACTIVE';
  });

  const isIdle = computed(() => {
    return chatStore.knownUsersByUuid.get(props.user.id)?.status === 'IDLE';
  });

  const showUserTooltip = (event: MouseEvent) => {
    /**
     * requestAnimationFrame is used to avoid a race condition when opening a new tooltip while another is still open.
     * Without this, the old tooltip might close but the new one may not display. This ensures the old tooltip is fully
     * closed before opening the new one.
     */

    requestAnimationFrame(() => {
      if (!props.user) {
        return;
      }

      chatStore.participantCardUser = props.user;
      chatStore.composerFocusStealOverride = true;
      chatStore.participantCardOpen = true;

      /**
       * We request an additional animation frame to ensure the tooltip is in the DOM,
       * allowing us to accurately retrieve its dimensions.
       */

      requestAnimationFrame(() => {
        let success = calculateTooltipPosition(event, 'chat-layer', 'participant-card', 'left');

        // If positioning the tooltip fails for any reason, close it and reset the values.
        if (!success) {
          chatStore.participantCardUser = null;
          chatStore.composerFocusStealOverride = false;
          chatStore.participantCardOpen = false;
        }
      });
    });
  };

  const resetStatusToolTip = () => {
    chatStore.userStatusTooltipMessage = null;
    chatStore.showUserStatusTooltip = false;
  };

  const showStatusTooltip = (event: MouseEvent, status: 'Online' | 'Idle' | 'Offline') => {
    requestAnimationFrame(() => {
      chatStore.userStatusTooltipMessage = status;
      chatStore.showUserStatusTooltip = true;

      requestAnimationFrame(() => {
        let success = calculateTooltipPosition(event, 'chat-layer2', 'user-status', 'right');
        // If positioning the tooltip fails for any reason, close it and reset the values.
        if (!success) {
          resetStatusToolTip();
        }
      });
    });
  };

  onMounted(() => {
    document.addEventListener('online', connectivityHandler);
    document.addEventListener('offline', connectivityHandler);
  });
</script>

<template>
  <div>
    <button class="w-full rounded-md px-2 py-1 hover:bg-surface-200/40 dark:hover:bg-surface-700/30" @click="showUserTooltip">
      <div class="flex items-center">
        <div class="relative shrink-0">
          <img v-if="user.profileUrl" class="relative h-9 w-9 shrink-0 select-none rounded-full object-cover ring-1 ring-surface-100 dark:dark:ring-surface-950" draggable="false" :src="user.profileUrl" alt="" />
          <div v-else class="relative flex shrink-0 items-center justify-center rounded-full text-sm ring-1 bg-surface-200 size-9 ring-surface-100 dark:ring-surface-950 dark:bg-surface-700">
            {{ getInitials(user.name) }}
          </div>
          <div v-if="isOnline" class="absolute bottom-0 rounded-full border-2 bg-green-500 size-3 -right-0.5 dark:border-surface-950 z-10" @mouseleave="resetStatusToolTip" @mouseenter="showStatusTooltip($event, 'Online')"></div>
          <div v-if="isIdle" class="absolute bottom-0 rounded-full border-2 bg-yellow-500 size-3 -right-0.5 dark:border-surface-950 z-10" @mouseleave="resetStatusToolTip" @mouseenter="showStatusTooltip($event, 'Idle')"></div>
          <div v-if="!isOnline && !isIdle" class="absolute bottom-0 rounded-full border-2 bg-surface-500 size-3 -right-0.5 dark:border-surface-950 z-10" @mouseleave="resetStatusToolTip" @mouseenter="showStatusTooltip($event, 'Offline')"></div>
        </div>
        <div class="ml-2">
          <div class="flex items-center text-left text-sm font-medium capitalize text-gray-800 dark:text-gray-300">
            <svg
              v-if="chatStore.isOperatorOnCurrentChannel(user.id)"
              class="h-4 w-4 shrink-0 stroke-amber-900 text-amber-400 mr-0.5"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="currentColor"
              stroke="currentColor"
              stroke-width="1"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M11.562 3.266a.5.5 0 0 1 .876 0L15.39 8.87a1 1 0 0 0 1.516.294L21.183 5.5a.5.5 0 0 1 .798.519l-2.834 10.246a1 1 0 0 1-.956.734H5.81a1 1 0 0 1-.957-.734L2.02 6.02a.5.5 0 0 1 .798-.519l4.276 3.664a1 1 0 0 0 1.516-.294z" />
              <path d="M5 21h14" />
            </svg>
            <span class="line-clamp-1">{{ user.name?.toLowerCase() }}</span>
          </div>
          <p class="text-left line-clamp-1 text-2xs text-surface-500 dark:text-surface-400">{{ chatStore.getUserStatus(user.id) }}</p>
        </div>
      </div>
    </button>
  </div>
</template>
