<script setup lang="ts">
  export interface Props {
    forId?: string;
    classes?: string;
    margins?: boolean;
    block?: boolean;
  }

  const { forId, classes } = withDefaults(defineProps<Props>(), {
    forId: 'no-id-given',
    classes: () => '',
    margins: true,
    block: true,
  });
</script>

<template>
  <div>
    <label :for="forId" class="text-sm font-medium text-surface-900 dark:text-white" :class="[margins ? 'mb-2' : '', block ? 'block' : '']">
      <slot></slot>
    </label>
  </div>
</template>
