import { Extension } from '@tiptap/core';
import { CommandProps } from '@tiptap/vue-3';

export interface CustomEnterStorage {
  disableEnter: boolean;
  mentionPanelOpened: boolean;
  emojisInlinePanelOpened: boolean;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    customEnter: {
      disableEnter: () => ReturnType;
      enableEnter: () => ReturnType;
      openEmojisInlinePanel: () => ReturnType;
      closeEmojisInlinePanel: () => ReturnType;
      openMentionsPanel: () => ReturnType;
      closeMentionsPanel: () => ReturnType;
    };
  }
}

// This is a callback passed in when creating the extension on the editor
export interface CustomEnterOptions {
  onEnter: () => void;
}

export default Extension.create<CustomEnterOptions, CustomEnterStorage>({
  name: 'customEnter',
  addStorage() {
    return {
      disableEnter: false,
      mentionPanelOpened: false,
      emojisInlinePanelOpened: false,
    };
  },
  addKeyboardShortcuts() {
    return {
      Enter: () => {
        if (this.storage.disableEnter) {
          return true;
        }
        if (this.storage.mentionPanelOpened || this.storage.emojisInlinePanelOpened) {
          return false;
        }

        const isHeadingActive = this.editor.isActive('heading');
        if (isHeadingActive) {
          return this.editor.commands.first(({ commands }) => [() => commands.createParagraphNear(), () => commands.liftEmptyBlock(), () => commands.splitBlock()]);
        }

        const isCodeBlockActive = this.editor.isActive('codeBlock') || this.editor.isActive('codeBlockLine') || this.editor.isActive('blockquote');
        if (isCodeBlockActive) {
          return this.editor.commands.first(({ commands }) => [() => commands.newlineInCode(), () => commands.createParagraphNear(), () => commands.liftEmptyBlock(), () => commands.splitBlock()]);
        }

        this.options.onEnter();
        return true;
      },
      'Shift-Enter': () => {
        const isBulletListActive = this.editor.isActive('orderedList') || this.editor.isActive('bulletList');
        const isListItemActive = this.editor.isActive('listItem');
        if (isBulletListActive && isListItemActive) {
          return this.editor.commands.first(({ commands }) => [() => commands.splitListItem('listItem'), () => commands.liftEmptyBlock(), () => commands.splitBlock()]);
        }
        if (this.storage.disableEnter) {
          return true;
        }
        return this.editor.commands.first(({ commands }) => [() => commands.newlineInCode(), () => commands.setHardBreak(), () => commands.createParagraphNear(), () => commands.liftEmptyBlock(), () => commands.splitBlock()]);
      },
    };
  },
  addCommands() {
    return {
      disableEnter:
        () =>
        ({ commands }: CommandProps) => {
          this.storage.disableEnter = true;
          console.log('set storage value', this.storage.disableEnter);
          return true;
        },
      enableEnter:
        () =>
        ({ commands }: CommandProps) => {
          this.storage.disableEnter = false;
          console.log('set storage value', this.storage.disableEnter);
          return true;
        },
      openEmojisInlinePanel: () => () => {
        this.storage.emojisInlinePanelOpened = true;
        return true;
      },
      closeEmojisInlinePanel: () => () => {
        this.storage.emojisInlinePanelOpened = false;
        return true;
      },
      openMentionsPanel: () => () => {
        this.storage.mentionPanelOpened = true;
        console.log('set storage value', this.storage.mentionPanelOpened);
        return true;
      },
      closeMentionsPanel: () => () => {
        this.storage.mentionPanelOpened = false;
        console.log('set storage value', this.storage.mentionPanelOpened);
        return true;
      },
    };
  },
});
