import { useAppStore } from '@src/store/app';
import {
  AbandonScreenshotCycleRequest,
  AbandonScreenshotCycleResponse,
  CallHomeResponse,
  CancelExternalRequestRequest,
  CancelInternalRequestRequest,
  CancelRequestResponse,
  ChangePasswordRequest,
  ChangePasswordResponse,
  CompleteScreenshotCycleRequest,
  CompleteScreenshotCycleResponse,
  ConfirmTaskRequest,
  ConfirmTaskResponse,
  CreateChatChannelResponse,
  CreateDirectChatChannelResponse,
  DashboardWarningsResponse,
  DownloadChatFileResponse,
  DownloadChatFilesResponse,
  EndBreakResponse,
  EndWorkRequest,
  EndWorkResponse,
  FinishChatFileUploadBatchResponse,
  GetActiveJobResponse,
  GetAssignedClientsResponse,
  GetChangesFromRequestsRequest,
  GetFeaturesResponse,
  GetLastMachineResponse,
  GetRequestsRequest,
  GetRequestsResponse,
  GetTimezonesResponse,
  GetUserDetailsRequest,
  GetUserDetailsResponse,
  GetWorkScheduleResponse,
  LoginUserRequest,
  LoginUserResponse,
  RefreshChatTokenResponse,
  RegisterMainComputerResponse,
  ReportBugResponse,
  ReportEventRequest,
  ReportEventResponse,
  ReportSystemInformationResponse,
  StartBreakResponse,
  StartChatFilesUploadBatchResponse,
  StartScreenshotCycleRequest,
  StartScreenshotCycleResponse,
  StartWorkRequest,
  StartWorkResponse,
  SubmitAdjustmentResponse,
  SubmitInternalAdjustmentRequest,
  SubmitLeaveRequestBeta,
  SubmitRequestRequest,
  SubmitSpeedTestRequest,
  SubmitSpeedTestResponse,
  SuccessResponse,
  TasksForCurrentPayrollCycleResponse,
  UpdateVaResponse,
  UploadApplicationsRequest,
  UploadApplicationsResponse,
  UploadAvatarResponse,
  UploadChatFileResponse,
  UploadProcessesRequest,
  UploadProcessesResponse,
  UploadWebsitesRequest,
  UploadWebsitesResponse,
} from '@src/types/MyTimeInTypes';
import { invoke } from '@tauri-apps/api/core';
import { fetch as taurifetch } from '@tauri-apps/plugin-http';
import { Logger } from '@utils/logger';

const log = new Logger('API');

export default class Api {
  baseApiUrl = '';
  baseApiUrlSet = false;
  accessToken = '';
  refreshToken = '';
  expiresAt = -1;
  isRefreshing = false;
  defaultHeaders: { [key: string]: string } = {
    Accept: 'application/json',
  };
  abortAllOperations = false;

  private reset() {
    this.baseApiUrl = '';
    this.baseApiUrlSet = false;
    this.accessToken = '';
    this.refreshToken = '';
    this.isRefreshing = false;
    this.expiresAt = 0;
  }

  /**
   * If the payload is too large or contains the text password, filter it out
   *
   * @param payload
   * @private
   */
  private filterPayload = (payload: string | null | undefined | FormData) => {
    if (!payload) {
      return 'empty';
    }
    if (payload instanceof FormData) {
      return 'formdata hidden';
    }
    if (payload.length > 1000) {
      return 'filtered (too large)';
    }
    if (payload.includes && payload.includes('password')) {
      return 'filtered';
    }
    return payload;
  };

  updatePassword = (payload: ChangePasswordRequest): Promise<ChangePasswordResponse> => {
    const request = new Request(`${this.baseApiUrl}account/update/password/`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: this.defaultHeaders,
    });

    return this.fetchWithThrow(request);
  };

  uploadAvatarViaFormData = (file: File): Promise<UploadAvatarResponse[]> => {
    const form = new FormData();
    form.append('avatar', file);
    const request = new Request(`${this.baseApiUrl}account/avatar/`, {
      method: 'POST',
      body: form,
      headers: this.defaultHeaders,
    });

    return this.fetchWithThrow(request);
  };

  updateAccount = (payload: AccountUpdateRequest): Promise<UpdateVaResponse> => {
    const url = `${this.baseApiUrl}account/update/va/`;
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  login = (payload: LoginUserRequest): Promise<LoginUserResponse> => {
    const url = `${this.baseApiUrl}auth/login/`;
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { ...this.defaultHeaders, 'Content-Type': 'application/json' },
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  logout = async () => {
    const url = `${this.baseApiUrl}auth/revoke/`;
    const response = await taurifetch(url, {
      method: 'POST',
      headers: this.defaultHeaders,
    });
    this.reset();
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(JSON.stringify(errorData));
    }
    return response.json();
  };

  getAnnouncements = () => {
    const url = `${this.baseApiUrl}meta/announcements/`;
    return this.fetchWithThrow(url, {
      method: 'GET',
      headers: this.defaultHeaders,
    });
  };

  cancelRequestInternal = (payload: CancelInternalRequestRequest): Promise<CancelRequestResponse> => {
    const url = `${this.baseApiUrl}request/cancelRequest`;
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  submitLeaveRequestInternal = async (payload: SubmitLeaveRequestBeta): Promise<RustResponse> => {
    const url = `${this.baseApiUrl}request/leave/`;
    const fetchOptions = {
      method: 'POST',
      body: this.convertPayloadToFormDataObject(payload),
      headers: this.defaultHeaders,
    };
    return await this.fetchWithThrow(url, fetchOptions);
  };

  getTimezones = (): Promise<GetTimezonesResponse> => {
    const url = `${this.baseApiUrl}meta/timezones/`;
    return this.fetchWithThrow(url, {
      method: 'GET',
      headers: this.defaultHeaders,
    });
  };

  submitBug = (payload: GenericObject): Promise<ReportBugResponse> => {
    const request = new Request(`${this.baseApiUrl}submitBug/`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: this.defaultHeaders,
    });

    return this.fetchWithThrow(request);
  };

  cancelAdjustmentExternal = (payload: CancelExternalRequestRequest): Promise<CancelRequestResponse> => {
    const url = `${this.baseApiUrl}request/cancelAdjustment/`;
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  cancelOvertimeExternal = (payload: CancelExternalRequestRequest): Promise<CancelRequestResponse> => {
    const url = `${this.baseApiUrl}request/cancelovertime/`;
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  cancelShiftChangeExternal = (payload: CancelExternalRequestRequest): Promise<CancelRequestResponse> => {
    const url = `${this.baseApiUrl}request/cancelshiftchange/`;
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  getRequests = (payload: GetRequestsRequest): Promise<GetRequestsResponse> => {
    // @ts-ignore
    payload.drop_empty = true;
    const url = `${this.baseApiUrl}request`;
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  getAllRequestsExternal = (): Promise<{
    requests: {
      overtime: OvertimeRequestExternal[];
      adjustments: AdjustmentRequestExternal[];
      schedule_changes: ScheduleChangeRequestExternal[];
    };
    jobs: ExternalJob[];
  }> => {
    const url = `${this.baseApiUrl}request`;
    const fetchOptions = {
      method: 'POST',
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  getActiveJob = (): Promise<GetActiveJobResponse> => {
    const url = `${this.baseApiUrl}work/getActiveJob/`;
    return this.fetchWithThrow(url, {
      method: 'GET',
      headers: this.defaultHeaders,
    });
  };

  getUpcomingLeaveExternal = () => {
    const url = `${this.baseApiUrl}request/upcomingleave/`;
    return this.fetchWithThrow(url, {
      method: 'GET',
      headers: this.defaultHeaders,
    });
  };

  getUpcomingScheduleChangesExternal = () => {
    const url = `${this.baseApiUrl}request/upcomingschedule/`;
    return this.fetchWithThrow(url, {
      method: 'GET',
      headers: this.defaultHeaders,
    });
  };

  getUpcomingOvertimeExternal = () => {
    const url = `${this.baseApiUrl}request/upcomingovertime/`;
    return this.fetchWithThrow(url, {
      method: 'GET',
      headers: this.defaultHeaders,
    });
  };

  submitAdjustment = (payload: SubmitInternalAdjustmentRequest): Promise<SubmitAdjustmentResponse> => {
    const url = `${this.baseApiUrl}request/adjustment/`;
    const fetchOptions = {
      method: 'POST',
      body: this.convertPayloadToFormDataObject(payload),
      // make this multipart form
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  SubmitExternalAdjustmentRequest = (payload: GenericObject): Promise<SubmitAdjustmentResponse> => {
    const url = `${this.baseApiUrl}request/adjustment/`;
    const fetchOptions = {
      method: 'POST',
      body: this.convertPayloadToFormDataObject(payload),
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  submitOvertimeRequestExternal = (payload: SubmitRequestRequest) => {
    const url = `${this.baseApiUrl}request/overtime/`;
    const fetchOptions = {
      method: 'POST',
      body: this.convertPayloadToFormDataObject(payload),
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  // TODO: FIX OVERTIME REQUEST
  submitOvertimeRequestInternal = (payload: OvertimeRequest) => {
    const url = `${this.baseApiUrl}request/submit/`;
    // convert to a form object
    const fetchOptions = {
      method: 'POST',
      body: this.convertPayloadToFormDataObject(payload),
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  private convertPayloadToFormDataObject(payload: any) {
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      // @ts-ignore
      if (payload[key] instanceof File) {
        // @ts-ignore
        formData.append(key, payload[key], payload[key].name); // Ensure file name is added
      } else {
        // @ts-ignore
        formData.append(key, payload[key]);
      }
    });
    return formData;
  }

  getScheduleChangesInternal = (payload: GetChangesFromRequestsRequest) => {
    const url = `${this.baseApiUrl}request/changes/`;
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  submitShiftChangeRequestInternal = async (payload: GenericObject): Promise<RustResponse> => {
    const url = `${this.baseApiUrl}request/shift/`;

    const formData = new FormData();
    formData.append('image', payload.image);
    formData.append('schedule', JSON.stringify(payload.schedule));
    formData.append('reason', payload.reason);
    formData.append('leave_start_date', payload.leave_start_date);
    formData.append('leave_end_date', payload.leave_end_date ?? '');
    // this is ghetto, i have no idea how this managed to work at all
    // let rawFileData = await (payload.image as File).text();
    // const uint8Array = new TextEncoder().encode(rawFileData);
    // const base64String = uint8Array.reduce((data, byte) => {
    //   return data + String.fromCharCode(byte);
    // }, '');
    // const weird_payload = {
    //   image: {
    //     type: 'file',
    //     client_name: (payload.image as File).name,
    //     mime_type: "image/png",
    //     // send the blob as base64 encoded
    //     blob: btoa(base64String),
    //   },
    //   schedule: JSON.stringify(payload.schedule),
    //   reason: payload.reason,
    //   leave_start_date: payload.leave_start_date,
    //   leave_end_date: payload.leave_end_date ?? '',
    // }
    const fetchOptions = {
      method: 'POST',
      body: formData,
      headers: { ...this.defaultHeaders },
    };
    // @ts-ignore
    const result = await this.fetchWithThrow(url, fetchOptions);

    if (result.status >= 300) {
      throw result;
    }

    return result;
  };

  submitQuickScheduleChangeExternal = (jobId: number | string, payload: GenericObject) => {
    const url = `${this.baseApiUrl}request/SaveQuickChange/?id=${jobId}`;
    log.debug({ ...payload, jobId });
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify({ ...payload, jobId }),
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  createLeaveExternal = (payload: GenericObject) => {
    const url = `${this.baseApiUrl}request/leave/create/`;
    // convert to FormData
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      // @ts-ignore
      if (payload[key] instanceof File) {
        // @ts-ignore
        formData.append(key, payload[key], payload[key].name); // Ensure file name is added
      } else {
        // @ts-ignore
        formData.append(key, payload[key]);
      }
    });
    const fetchOptions = {
      method: 'POST',
      body: formData,
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  getAbsentTasks = (params: GenericObject) => {
    const url = `${this.baseApiUrl}schedule/absent`;
    const query = new URLSearchParams(params).toString();
    return this.fetchWithThrow(`${url}?${query}`, {
      method: 'GET',
      headers: this.defaultHeaders,
    });
  };

  getCycles = () => {
    const url = `${this.baseApiUrl}schedule/cycles/`;
    const fetchOptions = {
      method: 'GET',
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  getLeaveCreditsInternal = (payload = {}) => {
    const url = `${this.baseApiUrl}request/credits/`;
    const fetchOptions = {
      method: 'GET',
      params: payload,
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  getScheduleJobs = () => {
    const url = `${this.baseApiUrl}schedule/jobs/`;
    return this.fetchWithThrow(url, {
      method: 'GET',
      headers: this.defaultHeaders,
    });
  };

  getSchedule = (clientId: string = ''): Promise<GetWorkScheduleResponse> => {
    let url = `${this.baseApiUrl}work/schedule`;

    if (clientId) {
      url = `${url}?clientId=${clientId}`;
    }

    return this.fetchWithThrow(url, {
      method: 'GET',
      headers: this.defaultHeaders,
    });
  };

  getScheduleById = (jobId: number | string): Promise<GetWorkScheduleResponse> => {
    const url = `${this.baseApiUrl}schedule/job/`;
    return this.fetchWithThrow(`${url}?id=${jobId}`, {
      method: 'GET',
      headers: this.defaultHeaders,
    });
  };

  reportEvent = (payload: ReportEventRequest): Promise<ReportEventResponse> => {
    const url = `${this.baseApiUrl}tracking/event/`;
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  callHome = (): Promise<CallHomeResponse> => {
    const url = `${this.baseApiUrl}work/call/`;
    const fetchOptions = {
      method: 'POST',
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  getServerFeatures = (): Promise<GetFeaturesResponse> => {
    const url = `${this.baseApiUrl}meta/features/`;
    return this.fetchWithThrow(url, {
      method: 'GET',
      headers: this.defaultHeaders,
    });
  };

  confirmAllTasks = (payload: { flag: boolean; taskIds: number[] }): Promise<ConfirmTaskResponse> => {
    const url = `${this.baseApiUrl}work/task/confirm/all/`;
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  confirmTask = (payload: ConfirmTaskRequest): Promise<ConfirmTaskResponse> => {
    const url = `${this.baseApiUrl}work/task/confirm/`;
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  endShift = (payload: EndWorkRequest): Promise<EndWorkResponse> => {
    const url = `${this.baseApiUrl}work/endWork/`;
    const fetchOptions = {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  endBreak = (): Promise<EndBreakResponse> => {
    const url = `${this.baseApiUrl}work/endBreak/`;
    const fetchOptions = {
      method: 'PUT',
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  getTasks = () => {
    const url = `${this.baseApiUrl}work/tasksforcurrentpayrollcycle/`;
    return this.fetchWithThrow(url, {
      method: 'GET',
      headers: this.defaultHeaders,
    });
  };

  getTasksForCycle = (params: GenericObject): Promise<TasksForCurrentPayrollCycleResponse> => {
    const url = `${this.baseApiUrl}work/tasksforcurrentpayrollcycle/`;
    const query = new URLSearchParams(params).toString();
    return this.fetchWithThrow(`${url}?${query}`, {
      method: 'GET',
      headers: this.defaultHeaders,
    });
  };

  startBreak = (): Promise<StartBreakResponse> => {
    const url = `${this.baseApiUrl}break/start/`;
    const fetchOptions = {
      method: 'PUT',
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  startShift = (payload: StartWorkRequest): Promise<StartWorkResponse> => {
    const url = `${this.baseApiUrl}work/start/`;
    const fetchOptions = {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  uploadSpeedTest = (speedInfo: SubmitSpeedTestRequest): Promise<SubmitSpeedTestResponse> => {
    const url = `${this.baseApiUrl}tracking/speedtest/`;
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(speedInfo),
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  uploadSystemInformation = (systemInformation: ComputerSpecs): Promise<ReportSystemInformationResponse> => {
    const url = `${this.baseApiUrl}tracking/sysinfo/`;
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify({ computer_information: systemInformation }),
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  getDiscordGamesList = () => {
    const url = `https://s3.ap-southeast-1.amazonaws.com/public.mytimein.com/assets/detectable.json`;
    return this.fetchWithThrow(url, { method: 'GET' });
  };

  getExcludedPathsList = () => {
    const url = `https://s3.ap-southeast-1.amazonaws.com/public.mytimein.com/assets/excluded_paths.json`;
    return this.fetchWithThrow(url, { method: 'GET' });
  };

  uploadMultipartFileToS3 = (url: string, file: File) => {
    const headers = {
      'User-Agent': 'Electron/',
      'Content-Type': file.type,
    };
    return this.uploadStreamToS3(url, file.stream() as any, 'bytes', headers);
  };

  uploadToS3 = (url: string, stream: any, complianceMode: boolean) => {
    const headers = {
      'x-amz-meta-compliance': '' + complianceMode,
      'User-Agent': 'Electron/',
    };
    return this.uploadStreamToS3(url, stream, 'Bytes', headers);
  };

  uploadInputData = (payload: GenericObject) => {
    const url = `${this.baseApiUrl}tracking/input/`;
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  uploadWebsites = (data: UploadWebsitesRequest): Promise<UploadWebsitesResponse> => {
    const url = `${this.baseApiUrl}tracking/websites/`;
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  uploadProcesses = (data: UploadProcessesRequest): Promise<UploadProcessesResponse> => {
    const url = `${this.baseApiUrl}tracking/processes/`;
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  uploadApplications = (data: UploadApplicationsRequest): Promise<UploadApplicationsResponse> => {
    const url = `${this.baseApiUrl}tracking/applications/`;
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  startGPTChat = async (message: string) => {
    const request: RustRequest = {
      url: this.baseApiUrl.replace('cyclone/', ''),
      slug: 'startChat',
      queryParams: '',
      method: 'POST',
      body: JSON.stringify({ message: message }),
      bodyType: 'Json',
      headers: { ...this.defaultHeaders },
    };

    return invoke('stream_request', { request });
  };

  getGptHistory = () => {
    const url = `${this.baseApiUrl}chatgpt/history/`;

    return this.fetchWithThrow(url, {
      method: 'GET',
      headers: this.defaultHeaders,
    });
  };

  setMainComputer = (uuid: string): Promise<RegisterMainComputerResponse> => {
    const url = `${this.baseApiUrl}tracking/registerMainComputer/`;
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify({ uuid }),
      headers: this.defaultHeaders,
    };

    return this.fetchWithThrow(url, fetchOptions);
  };

  getLastMachine = (): Promise<GetLastMachineResponse> => {
    const url = `${this.baseApiUrl}tracking/lastMachine/`;

    return this.fetchWithThrow(url, {
      method: 'GET',
      headers: this.defaultHeaders,
    });
  };

  getWarningsForLastShift = (): Promise<{ data: DashboardWarningsResponse }> => {
    const url = `${this.baseApiUrl}work/dashboardwarnings/`;

    return this.fetchWithThrow(url, {
      method: 'GET',
      headers: this.defaultHeaders,
    });
  };

  refreshTokens = async () => {
    if (this.isRefreshing) {
      return;
    }
    this.isRefreshing = true;
    const url = `${this.baseApiUrl}auth/refresh/`;
    const fetchOptions = {
      method: 'POST',
      headers: { ...this.defaultHeaders, 'refresh-token': this.refreshToken },
      body: '{}',
    };
    try {
      const data = await this.fetchWithThrow(url, fetchOptions);
      this.setTokens(data.access_token, data.refresh_token, data.expires_at);
      this.isRefreshing = false;
      return data;
    } catch (e) {
      log.error('Error refreshing tokens');
      log.error(e);
      this.isRefreshing = false;
      this.abortAllOperations = true;
      throw e;
    }
  };

  refreshChatToken = (): Promise<RefreshChatTokenResponse> => {
    const url = `${this.baseApiUrl}auth/chat/`;
    const fetchOptions = {
      method: 'POST',
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  getSignedCookie = (pubnubToken: string) => {
    const url = `https://myoutdeskdl.mytimein.com/cookie-time`;
    return this.nativeFetchWithThrow(url, {
      method: 'GET',
      headers: { authorization: pubnubToken },
      credentials: 'include',
    });
  };

  getClientsInternal = (): Promise<GetAssignedClientsResponse> => {
    const url = `${this.baseApiUrl}meta/clients`;

    return this.fetchWithThrow(url, {
      method: 'GET',
      headers: this.defaultHeaders,
    });
  };

  uploadStreamToS3 = (url: string, body: Uint8Array, _encoding: string, headers: GenericObject) => {
    const fetchOptions = {
      method: 'PUT',
      body: body,
      headers: headers,
      duplex: 'half',
    };
    return this.performFetchWithoutJson(url, fetchOptions);
  };

  me = (runtimeInformation: GetUserDetailsRequest): Promise<GetUserDetailsResponse> => {
    const url = `${this.baseApiUrl}auth/me/`;
    return this.fetchWithThrow(url, {
      method: 'POST',
      body: JSON.stringify(runtimeInformation),
      headers: this.defaultHeaders,
    });
  };

  searchChatUsers = (query: string): Promise<GenericObject> => {
    const url = `${this.baseApiUrl}chat/search/`;
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify({ query }),
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  getChatNetwork = (): Promise<GenericObject> => {
    const url = `${this.baseApiUrl}chat/chatnetwork/`;
    return this.fetchWithThrow(url, {
      method: 'GET',
      headers: this.defaultHeaders,
    });
  };

  abandonScreenshotCycle = async (payload: AbandonScreenshotCycleRequest): Promise<AbandonScreenshotCycleResponse> => {
    const url = `${this.baseApiUrl}screenshots/abandon/`;
    return this.fetchWithThrow(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: this.defaultHeaders,
    });
  };

  startScreenshotCycle = async (displayGroup: StartScreenshotCycleRequest): Promise<StartScreenshotCycleResponse> => {
    const url = `${this.baseApiUrl}screenshots/start/`;
    return this.fetchWithThrow(url, {
      method: 'POST',
      body: JSON.stringify(displayGroup),
      headers: this.defaultHeaders,
    });
  };

  completeScreenshotCycle = async (request: CompleteScreenshotCycleRequest): Promise<CompleteScreenshotCycleResponse> => {
    const url = `${this.baseApiUrl}screenshots/complete/`;
    return this.fetchWithThrow(url, {
      method: 'POST',
      body: JSON.stringify(request),
      headers: this.defaultHeaders,
    });
  };

  createChatChannel = ({ invite_uuids, type }: { invite_uuids: string[]; type: string }): Promise<CreateChatChannelResponse> => {
    const url = `${this.baseApiUrl}chat/CreateChannel/`;
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify({ invite_uuids, type }),
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  createDirectChatChannel = (invite_uuid: string): Promise<CreateDirectChatChannelResponse> => {
    const url = `${this.baseApiUrl}chat/CreateDirectChannel/`;
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify({ invite_uuid }),
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  inviteMembersToChatChannel = ({ channel_id, invite_uuids }: { channel_id: string; invite_uuids: string[] }): Promise<SuccessResponse> => {
    const url = `${this.baseApiUrl}chat/AddChannelMembers/`;
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify({ channel_id, invite_uuids }),
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  removeChannelMembers = ({ channel_id, remove_uuids }: { channel_id: string; remove_uuids: string[] }): Promise<SuccessResponse> => {
    const url = `${this.baseApiUrl}chat/RemoveChannelMembers/`;
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify({ channel_id, remove_uuids }),
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  startChatFileUploadBatch = (channel_id: string, files: string[]): Promise<StartChatFilesUploadBatchResponse> => {
    const url = `${this.baseApiUrl}chat/StartFileUploadBatch/`;
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify({ channel_id, files }),
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  finishUploadBatch = (batch_id: string): Promise<FinishChatFileUploadBatchResponse> => {
    const url = `${this.baseApiUrl}chat/FinishFileUploadBatch/}`;
    const fetchOptions = {
      method: 'POST',
      headers: this.defaultHeaders,
      body: JSON.stringify({ batch_id }),
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  abandonBatch = (batch_id: string): Promise<void> => {
    const url = `${this.baseApiUrl}chat/AbandonFileUploadBatch/`;
    const fetchOptions = {
      method: 'POST',
      headers: this.defaultHeaders,
      body: JSON.stringify({ batch_id }),
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  getFileUrl = (id: string): Promise<DownloadChatFileResponse> => {
    const url = `${this.baseApiUrl}chat/DownloadFile?id=${id}`;
    const fetchOptions = {
      method: 'GET',
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  getFileUrls = (ids: string[]): Promise<DownloadChatFilesResponse> => {
    const url = `${this.baseApiUrl}chat/DownloadFiles/`;
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify({ ids }),
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  uploadFileToChannel = (channel_id: string, file: File): Promise<UploadChatFileResponse> => {
    const url = `${this.baseApiUrl}chat/UploadFile/`;
    const formData = new FormData();
    formData.append('channel_id', channel_id);
    formData.append('file', file);
    const fetchOptions = {
      method: 'POST',
      body: formData,
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  deleteFile = (file_id: string): Promise<{ channel_id: string }> => {
    const url = `${this.baseApiUrl}chat/DeleteFile/${file_id}`;
    const fetchOptions = {
      method: 'DELETE',
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  pinMessageInChannel = (channel_id: string, timetoken: string): Promise<SuccessResponse> => {
    const url = `${this.baseApiUrl}chat/PinMessage/`;
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify({ channel_id, timetoken }),
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  unpinMessageInChannel = (channel_id: string, timetoken: string): Promise<SuccessResponse> => {
    const url = `${this.baseApiUrl}chat/UnpinMessage/`;
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify({ channel_id, timetoken }),
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  setTokens = (accessToken: string, refreshToken: string, expiresAt: number) => {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    this.expiresAt = expiresAt;
    // TODO: REFACTOR
    this.defaultHeaders['token'] = accessToken;
    this.abortAllOperations = false;

    // also update the app state
    const state = useAppStore();
    if (state.currentUserAuth) {
      state.currentUserAuth.accessToken = accessToken;
      state.currentUserAuth.refreshToken = refreshToken;
      state.currentUserAuth.expiresAt = expiresAt;
      state.commitUpdatedAuthDetails().then(() => {
        log.debug('Tokens updated');
      });
    }
  };

  setInstance = (instance: string, development: boolean): void => {
    this.baseApiUrl = `https://${instance}.mytimein.${development ? 'test' : 'com'}/cyclone/`;
    this.baseApiUrlSet = true;
  };

  reportMeetingStatus = (status: boolean) => {
    const url = `${this.baseApiUrl}tracking/meeting/`;
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify({ in_meeting: status }),
      headers: this.defaultHeaders,
    };
    return this.fetchWithThrow(url, fetchOptions);
  };

  getBaseApiUrl = (): string => {
    return this.extractSubdomainFromFullUrl(this.baseApiUrl);
  };

  extractSubdomainFromFullUrl = (url: string): string => {
    try {
      const urlObject = new URL(url);
      const hostname = urlObject.hostname;

      // Split the hostname into parts
      const parts = hostname.split('.');

      // Check if there are more than two parts (at least domain and TLD)
      if (parts.length > 2) {
        // Join all parts except the last two (domain and TLD)
        return parts.slice(0, -2).join('.');
      }
      return 'myoutdesk';
    } catch (error) {
      log.error('Error extracting subdomain from full url');
      log.error(error);
      return 'myoutdesk';
    }
  };

  private async nativeFetchWithThrow(url: Request | string, options?: RequestInit): Promise<any> {
    log.debug({
      url: url,
      method: options?.method,
      payload: this.filterPayload(options?.body as string),
    });
    const response = await fetch(url, options);
    if (!response.ok) {
      log.error({
        url: url,
        responseStatus: response.status,
        responseStatusText: response.statusText,
      });
      // @ts-ignore
      if (response.status === 401 && !url.includes('auth/refresh/') && !url.includes('auth/login/')) {
        return await this.waitForRefreshThenCall((token) => {
          // @ts-ignore
          options.headers.token = token;
          return this.nativeFetchWithThrow(url, options);
        });
      } else {
        log.warn('Not handling unauthorized?', response.status);
      }
      //I think this can either be JSON or Server error which is HTML in Yii
      throw await response.json();
    }
    return await response.json();
  }

  private async fetchWithThrow(url: Request | string, options?: RequestInit): Promise<any> {
    log.debug({
      url: url,
      method: options?.method,
      payload: this.filterPayload(options?.body as string),
    });
    const response = await taurifetch(url, options);
    if (!response.ok) {
      log.error({
        url: url,
        responseStatus: response.status,
        responseStatusText: response.statusText,
      });
      // @ts-ignore
      if (response.status === 401 && !url.includes('auth/refresh/') && !url.includes('auth/login/')) {
        return await this.waitForRefreshThenCall((token) => {
          // @ts-ignore
          options.headers.token = token;
          return this.fetchWithThrow(url, options);
        });
      } else {
        log.warn('Not handling unauthorized?', response.status);
      }
      //I think this can either be JSON or Server error which is HTML in Yii
      throw await response.json();
    }
    return await response.json();
  }

  private waitForRefreshOperation = async (): Promise<any> => {
    return new Promise((resolve) => {
      const checkInterval = setInterval(() => {
        if (!this.isRefreshing) {
          clearInterval(checkInterval);
          resolve(0);
        }
      }, 1000);
    });
  };

  private waitForRefreshThenCall = async (callableFunction: (token: string) => Promise<any>) => {
    log.debug('auth token expired, refreshing');
    if (!this.isRefreshing && !this.abortAllOperations) {
      await this.refreshTokens();
    } else if (this.isRefreshing) {
      log.debug('waiting on auth refresh');
      await this.waitForRefreshOperation();
    }
    if (this.abortAllOperations) {
      log.debug('aborting all operations');
      throw new Error('Aborting all operations');
    }
    log.debug('recalling original function');
    return await callableFunction(this.accessToken);
  };

  private performFetchWithoutJson = async (url: Request | string, options?: RequestInit): Promise<any> => {
    log.debug({
      url: url,
      method: options?.method,
      payload: this.filterPayload(options?.body as string),
    });
    const response = await taurifetch(url, options);

    if (!response.ok) {
      if (response.status === 401) {
        return await this.waitForRefreshThenCall((token: string) => {
          // @ts-ignore
          options.headers.token = token;
          return this.fetchWithThrow(url, options);
        });
      }
      if (response.status === 403) {
        throw new Error('Forbidden');
      }
    }
  };
}
