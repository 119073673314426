<script setup lang="ts">
  import { ref, onBeforeMount, computed } from 'vue';
  import { Channel } from '@pubnub/chat';
  import { ChatBubbleLeftEllipsisIcon, StarIcon, UserGroupIcon } from '@heroicons/vue/16/solid';
  import GroupChannelListItem from '@components/Chat/GroupChannelListItem.vue';
  import Skeleton from 'primevue/skeleton';
  import Button from 'primevue/button';
  import { useChatStore } from '@src/store/chat';
  import AnimateSlideDown from '@components/Shared/AnimateSlideDown.vue';
  import ChatChannelCategoryButton from '@components/Chat/ChatChannelCategoryButton.vue';
  import { Tippy } from 'vue-tippy';
  import { Sortable } from 'sortablejs-vue3';
  import { SortableGroupChannel } from '@src/types/ChatAndMessaging';

  const emit = defineEmits(['change-channel', 'favorite', 'unfavorite', 'delete-channel', 'hide-channel', 'load-group-channels', 'create', 'leave']);

  const props = defineProps<{
    currentChannelUrl: string;
    teamChannels: Channel[];
    favoriteChannels: Channel[];
    nonFavoriteGroupChannels: Channel[];
    loading: boolean;
  }>();

  const showTeams = ref(true);
  const showConversations = ref(true);
  const showFavorites = ref(true);
  const chatStore = useChatStore();
  const editMode = ref(false);
  const teamsOrder = ref<SortableGroupChannel[]>([]);
  const favoritesOrder = ref<SortableGroupChannel[]>([]);

  const sortableOptions = {
    draggable: '.draggable',
    animation: 150,
    handle: '.drag-handle',
    ghostClass: 'ghost',
    chosenClass: 'channel-sorting',
    scroll: true,
    forceFallback: true,
    bubbleScroll: true,
  };

  const toggleShowTeams = () => {
    showTeams.value = !showTeams.value;
  };
  const toggleShowConversations = () => {
    showConversations.value = !showConversations.value;
  };
  const toggleShowFavorites = () => {
    showFavorites.value = !showFavorites.value;
  };

  /**
   * Orders the prop favoriteChannels based on the order in chatFavoritesOrder, with items not in the chatFavoritesOrder at the end.
   */
  const orderedChatFavorites = computed(() => {
    const orderedChannels = favoritesOrder.value.map((order) => props.favoriteChannels.find((channel) => channel.id === order.id)).filter((channel) => channel);
    const unorderedChannels = props.favoriteChannels.filter((channel) => !orderedChannels.includes(channel));
    return [...orderedChannels, ...unorderedChannels];
  });

  /**
   * Orders the prop teamChannels based on the order in chatTeamsOrder, with items not in the chatTeamsOrder at the end.
   */
  const orderedChatTeams = computed(() => {
    const orderedChannels = teamsOrder.value.map((order) => props.teamChannels.find((channel) => channel.id === order.id)).filter((channel) => channel);
    const unorderedChannels = props.teamChannels.filter((channel) => !orderedChannels.includes(channel));
    return [...orderedChannels, ...unorderedChannels];
  });

  const handleDragFinishFavorites = (event: any) => {
    if (event.oldIndex === event.newIndex) {
      return;
    }

    const currentOrder = [...orderedChatFavorites.value];
    const [movedItem] = currentOrder.splice(event.oldIndex, 1);
    currentOrder.splice(event.newIndex, 0, movedItem);

    let updatedFavoritesOrder: SortableGroupChannel[] = [];
    currentOrder.forEach((item: Channel | undefined, index: number) => {
      if (!item) {
        return;
      }
      updatedFavoritesOrder.push({
        id: item.id,
        name: item.name ?? item.id,
        position: index,
      });
    });

    favoritesOrder.value = updatedFavoritesOrder;
    localStorage.setItem('favoriteChannelOrder', JSON.stringify(updatedFavoritesOrder));
  };

  const handleDragFinishTeams = (event: any) => {
    if (event.oldIndex === event.newIndex) {
      return;
    }

    const currentOrder = [...orderedChatTeams.value];
    const [movedItem] = currentOrder.splice(event.oldIndex, 1);
    currentOrder.splice(event.newIndex, 0, movedItem);

    let updatedTeamsOrder: SortableGroupChannel[] = [];
    currentOrder.forEach((item: Channel | undefined, index: number) => {
      if (!item) {
        return;
      }
      updatedTeamsOrder.push({
        id: item.id,
        name: item.name ?? item.id,
        position: index,
      });
    });

    teamsOrder.value = updatedTeamsOrder;
    localStorage.setItem('teamChannelOrder', JSON.stringify(updatedTeamsOrder));
  };

  onBeforeMount(() => {
    const favoriteChannelOrder = localStorage.getItem('favoriteChannelOrder');
    const teamChannelOrder = localStorage.getItem('teamChannelOrder');
    if (favoriteChannelOrder) {
      favoritesOrder.value = JSON.parse(favoriteChannelOrder).sort((a: SortableGroupChannel, b: SortableGroupChannel) => a.position - b.position);
    }
    if (teamChannelOrder) {
      teamsOrder.value = JSON.parse(teamChannelOrder).sort((a: SortableGroupChannel, b: SortableGroupChannel) => a.position - b.position);
    }
  });
</script>

<template>
  <div class="z-10 w-[16rem] flex shrink-0 select-none flex-col border-r border-surface-100 bg-surface-50/95 space-y-4 dark:border-surface-800 dark:bg-surface-800/50 md:max-h-screen md:min-h-screen" @contextmenu.stop.prevent="chatStore.closeAllOpenMenus()">
    <div class="flex shrink-0 items-center px-2 pt-1 h-[64px]">
      <div class="flex-grow text-lg font-bold">MyOutDesk</div>
      <div class="space-x-1 flex">
        <div v-if="favoritesOrder.length > 0 || orderedChatTeams.length > 0">
          <tippy v-if="!editMode">
            <template #content>
              <div class="rounded-md border p-2 text-xs shadow-md bg-surface-0 dark:bg-surface-700 dark:text-surface-100 dark:border-black">Edit Order</div>
            </template>
            <Button severity="secondary" size="small" icon="pi pi-list-check" @click="editMode = true" />
          </tippy>
          <tippy v-if="editMode">
            <template #content>
              <div class="rounded-md border p-2 text-xs shadow-md bg-surface-0 dark:bg-surface-700 dark:text-surface-100 dark:border-black">Save Order</div>
            </template>
            <Button severity="info" size="small" icon="pi pi-save" @click="editMode = false" />
          </tippy>
        </div>
        <tippy>
          <template #content>
            <div class="rounded-md border p-2 text-xs shadow-md bg-surface-0 dark:bg-surface-700 dark:text-surface-100 dark:border-black">Start Conversation</div>
          </template>
          <Button severity="secondary" size="small" icon="pi pi-pen-to-square" data-testid="create-channel" @click="emit('create')" />
        </tippy>
      </div>
    </div>
    <div class="overflow-y-auto small-scroll space-y-2">
      <!-- Team Channels -->
      <div>
        <ChatChannelCategoryButton v-if="!loading" :is-open="showTeams" :show-toggle="teamChannels.length > 0" @toggle="toggleShowTeams">
          <template #icon>
            <UserGroupIcon class="size-5" />
          </template>
          <template #category>Teams</template>
        </ChatChannelCategoryButton>

        <AnimateSlideDown v-if="!loading" :show="showTeams">
          <template #default>
            <Sortable :list="orderedChatTeams" item-key="id" tag="div" :options="sortableOptions" data-testid="team-channel-list" @end="handleDragFinishTeams">
              <template #item="{ element, index }">
                <div :key="element.id" class="draggable">
                  <GroupChannelListItem
                    :key="`group-${index}`"
                    :channel="element"
                    :current-channel-url="currentChannelUrl"
                    :mentions="chatStore.unreadMentions.get(element.id) ?? 0"
                    :alerts="chatStore.channelAlerts.get(element.id) ?? 0"
                    :can-favorite="false"
                    :favorite="true"
                    :draggable="editMode"
                    @favorite="emit('favorite', element.id)"
                    @unfavorite="emit('unfavorite', element.id)"
                    @change-channel="emit('change-channel', element.id)"
                    @delete="emit('delete-channel', element.id)"
                    @hide="emit('hide-channel', element.id)"
                    @leave="chatStore.leaveChannel(element.id)"
                  />
                </div>
              </template>
            </Sortable>
          </template>
        </AnimateSlideDown>
        <Skeleton v-else height="22px" class="mb-2 w-full" />
      </div>
      <!--Favorites-->
      <div>
        <ChatChannelCategoryButton v-if="!loading && favoriteChannels.length > 0" :is-open="showFavorites" :show-toggle="favoriteChannels.length > 0" @toggle="toggleShowFavorites">
          <template #icon>
            <StarIcon class="size-5" />
          </template>
          <template #category>Favorites</template>
        </ChatChannelCategoryButton>
        <AnimateSlideDown v-if="!loading" :show="showFavorites">
          <template #default>
            <Sortable :list="orderedChatFavorites" item-key="id" tag="div" :options="sortableOptions" data-testid="favorites-channel-list" @end="handleDragFinishFavorites">
              <template #item="{ element, index }">
                <div :key="element.id" class="draggable">
                  <GroupChannelListItem
                    :key="`group-${index}`"
                    :channel="element"
                    :current-channel-url="currentChannelUrl"
                    :mentions="chatStore.unreadMentions.get(element.id) ?? 0"
                    :alerts="chatStore.channelAlerts.get(element.id) ?? 0"
                    :favorite="true"
                    :draggable="editMode"
                    @favorite="emit('favorite', element.id)"
                    @unfavorite="emit('unfavorite', element.id)"
                    @change-channel="emit('change-channel', element.id)"
                    @delete="emit('delete-channel', element.id)"
                    @hide="emit('hide-channel', element.id)"
                    @leave="chatStore.leaveChannel(element.id)"
                  />
                </div>
              </template>
            </Sortable>
          </template>
        </AnimateSlideDown>
        <Skeleton v-else height="22px" class="mb-2 w-full" />
      </div>
      <!--Conversations-->
      <div>
        <ChatChannelCategoryButton v-if="!loading" :is-open="showConversations" :show-toggle="nonFavoriteGroupChannels.length > 0" @toggle="toggleShowConversations">
          <template #icon>
            <ChatBubbleLeftEllipsisIcon class="size-5" />
          </template>
          <template #category>Conversations</template>
        </ChatChannelCategoryButton>

        <AnimateSlideDown v-if="!loading" :show="showConversations">
          <div v-if="showConversations" data-testid="group-channel-list">
            <div v-if="!nonFavoriteGroupChannels.length" class="px-4 py-1">
              <Button size="small" @click="emit('create')">Start New Conversation</Button>
            </div>
            <GroupChannelListItem
              v-for="(channel, groupChannelIndex) in nonFavoriteGroupChannels"
              v-else
              :key="`group-${groupChannelIndex}`"
              :channel="channel"
              :current-channel-url="currentChannelUrl"
              :mentions="chatStore.unreadMentions.get(channel.id) ?? 0"
              :alerts="chatStore.channelAlerts.get(channel.id) ?? 0"
              :favorite="false"
              @favorite="emit('favorite', channel.id)"
              @unfavorite="emit('unfavorite', channel.id)"
              @change-channel="emit('change-channel', channel.id)"
              @delete="emit('delete-channel', channel.id)"
              @hide="emit('hide-channel', channel.id)"
              @leave="chatStore.leaveChannel(channel.id)"
            />
          </div>
        </AnimateSlideDown>
        <Skeleton v-else height="22px" class="mb-2 w-full" data-testid="chat-channel-list-skeleton" />
      </div>
    </div>
  </div>
</template>
