import { MtiModule } from '@modules/index';
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import { emojiDirective } from '@src/directives/emoji';
// @ts-ignore
import mti from '@src/presets/mytimeinapp';

export default <MtiModule>{
  onVueAppInit(app) {
    app.use(PrimeVue, { ripple: true, unstyled: true, pt: mti });
    app.use(ConfirmationService);
    app.use(ToastService);
    app.directive('emoji', emojiDirective);
    app.directive('tooltip', Tooltip);
  },
};
