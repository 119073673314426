import { VueRenderer } from '@tiptap/vue-3';
import ChatMentionList from '@components/Chat/ChatMentionList.vue';
import tippy, { Instance as TippyInstance, Props } from 'tippy.js';

const ChatMentionSuggestion = {
  render: () => {
    let component: VueRenderer;
    let popup: TippyInstance<Props>;

    const closePopup = () => {
      component.editor.chain().closeMentionsPanel();
      component.destroy();
    };

    return {
      onStart: (props: any) => {
        component = new VueRenderer(ChatMentionList, {
          props,
          editor: props.editor,
        });

        if (!props.clientRect) {
          return;
        }

        const editorElement = document.getElementById('message-editor');

        const setPopupWidth = (instance: TippyInstance<Props>) => {
          const tippyBox = instance.popper.querySelector('.tippy-box') as HTMLElement;
          if (tippyBox) {
            tippyBox.style.maxWidth = 'unset'; // Unset the max-width property
          }

          if (editorElement) {
            instance.popper.style.width = `${editorElement.getBoundingClientRect().width}px`;
            instance.popper.style.maxWidth = 'unset';
          }
        };

        //@ts-ignore
        popup = tippy(document.getElementById('composer-container'), {
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'top',
          onShow: (instance) => {
            props.editor.chain().openMentionsPanel();
            setPopupWidth(instance);
          },
          onHide: () => {},
        });

        // Handle window resize to adjust popup width
        window.addEventListener('resize', () => setPopupWidth(popup));
      },

      onUpdate(props: any) {
        // Destructure props to get query and items
        const { query, items } = props;

        // Filter items based on the query
        const filteredItems = items.filter((item: any) => {
          return item.name.toLowerCase().includes(query.toLowerCase());
        });

        // Update component props with filtered items
        component.updateProps({ ...props, items: filteredItems });
      },

      onKeyDown(props: any) {
        if (props.event.key === 'Escape') {
          closePopup();
        }

        return component.ref?.onKeyDown(props);
      },

      onExit() {
        closePopup();
      },
    };
  },
};

export default ChatMentionSuggestion;
