<script setup lang="ts">
  import { useRouter } from 'vue-router';
  import { useAppStore } from '@src/store/app';
  import { useChatStore } from '@src/store/chat';
  import { useActiveJobStore } from '@src/store/activejob';
  import Toast from 'primevue/toast';
  import ConfirmDialog from 'primevue/confirmdialog';
  import Button from 'primevue/button';
  import { onMounted, onUnmounted, ref } from 'vue';
  import { open } from '@tauri-apps/plugin-shell';
  import { sep } from '@tauri-apps/api/path';
  import SidebarNavigation from '@src/layout/SidebarNavigation.vue';
  import ChatView from '@src/views/ChatView.vue';
  import StartShift from '@components/Dashboard/StartShift.vue';
  import Dialog from 'primevue/dialog';
  import { listen } from '@tauri-apps/api/event';
  import { Logger } from '@utils/logger';

  const log = new Logger('APP');
  const router = useRouter();
  const appState = useAppStore();
  const activeJobStore = useActiveJobStore();
  const chatStore = useChatStore();
  const tauriNavigationListener = ref();

  appState.$subscribe((mutation, state) => {
    if (router.currentRoute.value.name !== 'root' && router.currentRoute.value.name === 'login' && state.currentUser) {
      router.push({ name: 'dashboard' });
    }
    if (state.currentUser === null && router.currentRoute.value.name !== 'login') {
      router.push({ name: 'login' });
    }
    updateTheme();
  });

  const updateTheme = () => {
    const { theme } = appState.settings;
    const isDark = theme === 'dark' || (theme === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches);
    document.body.classList.toggle('dark', isDark);
  };

  const detectBrokenSafariVariableFonts = () => {
    let root = document.getElementsByTagName('html')[0];
    if (navigator.userAgent.includes('AppleWebKit/605.1.15 ')) {
      root.classList.add('safariFontFix');
    }
  };

  const setClockingIn = (flag: boolean) => {
    activeJobStore.additionalDetails.clockingIn = flag;
    chatStore.composerFocusStealOverride = flag;
  };

  const registerHotKeys = () => {
    unRegisterHotKeys(); //just in case they are registered un listen
    document.addEventListener('keydown', handleKeyboardShortcut);
  };

  const handleKeyboardShortcut = (event: KeyboardEvent) => {
    // Check if either Control or Command is pressed along with the "R" key
    // CMD + SHIFT + R or CMD + $
    if (((event.ctrlKey || event.metaKey) && event.code === 'KeyR') || ((event.ctrlKey || event.metaKey) && event.shiftKey && event.code === 'KeyR')) {
      //This is really only here because i can't trust that the user said they didn't push this
      log.info('User pressed control R');
      event.preventDefault();
      window.location.href = `${window.location.pathname}?timestamp=${new Date().getTime()}`;
    }
  };

  const unRegisterHotKeys = () => {
    document.removeEventListener('keydown', handleKeyboardShortcut);
  };

  onMounted(async () => {
    registerHotKeys();
    detectBrokenSafariVariableFonts();
    tauriNavigationListener.value = listen('rs_navigation', async () => {
      log.debug('navigation event received');
      const chatStore = useChatStore();
      await chatStore.disconnect();
      log.debug('allowing reload');
      return true;
    });
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', updateTheme);
  });

  onUnmounted(() => {
    unRegisterHotKeys();
    window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', updateTheme);
    //sometimes it's not a function?
    if (tauriNavigationListener.value && typeof tauriNavigationListener.value === 'function') {
      tauriNavigationListener.value();
    }
  });

  const openFilePath = (path: string, showInFolder: boolean = false) => {
    if (!showInFolder) {
      open(path);
      return;
    }
    // Detect the file path separator based on the operating system
    const parts = path.split(sep());
    parts.pop(); // Remove the file name to get the directory path
    const directory = parts.join(sep());
    open(directory); // Open the directory
  };
</script>

<template>
  <div v-if="router.currentRoute.value.name">
    <ConfirmDialog group="message-delete">
      <template #container="{ message, rejectCallback, acceptCallback }">
        <div class="p-4 space-y-4 dark:text-white dark:bg-surface-700 ring-1 ring-surface-400 ring-inset dark:ring-surface-950 rounded-md">
          <div class="text-xl font-mediums">{{ message.header }}</div>
          <div class="flex items-center w-full gap-2 text-sm">
            <p>{{ message.message }}</p>
          </div>
          <div class="flex items-center justify-end gap-2 mt-4">
            <Button :label="message.rejectLabel" severity="secondary" outlined class="w-[8rem]" @click="rejectCallback"></Button>
            <Button :label="message.acceptLabel" severity="danger" class="w-[8rem]" @click="acceptCallback"></Button>
          </div>
        </div>
      </template>
    </ConfirmDialog>
    <Toast position="top-right" group="tr">
      <template #message="slotProps">
        <div class="w-full space-y-3">
          <div>{{ slotProps.message.summary }}</div>
          <div class="text-xs break-all">
            <span class="font-bold">{{ slotProps.message.detail.fileName }}</span>
            has been saved to: {{ slotProps.message.detail.outputPath }}
          </div>
          <div class="flex justify-end gap-x-2 text-right">
            <Button size="small" @click="openFilePath(slotProps.message.detail.outputPath, true)">Show In Folder</Button>
            <Button size="small" @click="openFilePath(slotProps.message.detail.outputPath)">Open</Button>
          </div>
        </div>
      </template>
    </Toast>
    <Toast position="bottom-right" class="break-all" />
    <Dialog v-model:visible="activeJobStore.additionalDetails.clockingIn" header="Start of Day" class="w-full sm:w-3/4 md:w-1/2" modal>
      <StartShift @shift-started="setClockingIn(false)" @close="setClockingIn(false)" />
    </Dialog>
    <div class="flex max-h-screen min-h-screen">
      <SidebarNavigation v-if="!['login', 'root'].includes(router.currentRoute.value.name as string)" class="top-0 left-0 hidden h-screen max-h-screen md:fixed md:block" />
      <div class="flex-1 overflow-auto" :class="[!['login', 'root'].includes(router.currentRoute.value.name as string) ? 'md:ml-[6.0625rem]' : '']">
        <ChatView v-if="appState.currentUser?.id && !appState.isExternalServer" v-show="router.currentRoute.value.name === 'chat'" class="select-none" />
        <router-view v-slot="{ Component }">
          <keep-alive :max="10">
            <component :is="Component" />
          </keep-alive>
        </router-view>
      </div>
    </div>
  </div>
</template>
