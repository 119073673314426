import { defineStore } from 'pinia';
import { invoke } from '@tauri-apps/api/core';
import { Logger } from '@utils/logger';
import { useRuntimeConfigurationStore } from '@src/store/runtimeconfiguration';
import Api from '@src/api';
import { EmojiManager } from '@utils/chat/emoji-manager';
import { AppStateStore } from '@src/types/AppStateStore';

const log = new Logger('STORE:APP_STATE');

export const useAppStore = defineStore('app_state', {
  state: (): AppStateStore => ({
    settings: {
      theme: 'system',
      runOnStartup: true,
      showSystemNotifications: true,
      breakReminder: true,
      shiftReminderTime: 15,
      shiftAfterEndReminderTime: 15,
      emojiSkinTone: undefined,
    },
    refreshPastShifts: false,
    authSettingsVersion: 2,
    api: new Api(),
    updateAvailable: false,
    users: [],
    currentUser: null,
    currentUserAuth: null,
    instanceType: null,
    gameList: [],
    excludedProcessList: [],
    announcements: [],
    favoriteChannels: [],
    recentlyUsedEmojis: [],

    loggingIn: false,
    refreshingAccessToken: false,
  }),
  actions: {
    async boot() {
      try {
        await this.fetchSettings();
        EmojiManager.getInstance().setSkinTone(this.settings.emojiSkinTone);
        this.loadRecentlyUsedEmojis();

        if (!this.currentUser) {
          this.loggingIn = false;
        } else {
          await this.validateTokenAndRefreshUser();
        }
        this.getFavoriteChannels();
      } catch (e) {
        log.error("Couldn't boot the app");
        log.error(e);
      }
    },
    async validateTokenAndRefreshUser(_abortEarly = false) {
      if (!this.currentUser || !this.currentUserAuth) {
        return;
      }
      this.loggingIn = true;
      const runtimeConfigurationStore = useRuntimeConfigurationStore();
      try {
        const response = await this.api.me({
          version: runtimeConfigurationStore.version,
          platform: runtimeConfigurationStore.platform,
        });
        this.currentUserAuth = {
          id: response.user.id,
          instance: this.api.getBaseApiUrl(),
          accessToken: response.access_token,
          refreshToken: response.refresh_token,
          expiresAt: response.expires_at,
          chatToken: response.chat_token,
          version: this.authSettingsVersion,
        };

        //unescape response.user.timezone for some reason MTI doesn't unescape it
        let timezone: string = response.user.timezone;
        timezone = timezone.replace(/\\_/g, '_');

        this.currentUser = {
          first_name: response.user.first_name,
          last_name: response.user.last_name,
          email: response.user.email,
          id: response.user.id,
          timezone: timezone,
          chat_nickname: response.user.chat_nickname,
          full_name: response.user.full_name,
          avatar: response.user.avatar,
          created_at: response.user.created_at,
        };
        // set the api
        this.api.setTokens(response.access_token, response.refresh_token, response.expires_at);
        this.loggingIn = false;
        await this.commitUpdatedAuthDetails();
      } catch (error: any) {
        //delete user from storage something went wrong
        localStorage.removeItem(`auth_${this.currentUser.id}`);

        // api layer should have caught this...
        this.loggingIn = false;
        this.currentUser = null;
        this.currentUserAuth = null;
        this.api.setTokens('', '', 0);
        // await this.commitUpdatedAuthDetails();

        return;
      }

      this.loggingIn = false;
    },
    loadUsersFromLocalStorage() {
      const keys = Object.keys(localStorage).filter((key) => key.startsWith('user_'));
      for (const key of keys) {
        const user = localStorage.getItem(key);
        if (user) {
          // TODO: MAKE SURE VALUE IS WELL FORMED
          this.users.push(JSON.parse(user));
        }
      }
    },
    localApplicationSettingsFromLocalStorage() {
      const settings = localStorage.getItem('settings');
      if (settings) {
        this.settings = JSON.parse(settings);
      }
    },
    async fetchSettings() {
      this.localApplicationSettingsFromLocalStorage();
      this.loadUsersFromLocalStorage();
      if (this.users.length > 1 || this.users.length === 0) {
        return;
      }
      const auth = localStorage.getItem(`auth_${this.users[0].id}`);
      if (!auth) {
        this.dumpUsersDueToInvalidConfig();
        return;
      }
      this.currentUser = this.users[0];
      this.currentUserAuth = JSON.parse(auth);
      // make sure we have a match and that the schema version matches
      if (this.currentUserAuth && this.currentUserAuth.version && this.currentUserAuth.version === this.authSettingsVersion) {
        this.setInstanceUrl(this.currentUserAuth.instance);
        this.api.setTokens(this.currentUserAuth.accessToken, this.currentUserAuth.refreshToken, this.currentUserAuth.expiresAt);
      } else {
        this.dumpUsersDueToInvalidConfig();
      }
    },
    async commitUpdatedAuthDetails() {
      if (!this.currentUser || !this.currentUserAuth) {
        return;
      }
      localStorage.setItem(`auth_${this.currentUser.id}`, JSON.stringify(this.currentUserAuth));
    },
    // TODO: REFACTOR
    setInstanceUrl(subdomain: string) {
      if (subdomain.includes('myoutdesk')) {
        this.instanceType = 'internal';
        this.api.setInstance(subdomain, this.isDevelopmentEnvironment);
      }
      if (this.isDevelopmentEnvironment) {
        if (subdomain === 'internal') {
          this.instanceType = 'internal';
        } else if (subdomain === 'external') {
          this.instanceType = 'external';
        }
        this.api.setInstance(subdomain, this.isDevelopmentEnvironment);
      }
      if (this.forceProductionOnDevelopment && subdomain === 'internal') {
        this.instanceType = 'internal';
        this.api.setInstance('myoutdesk', false);
      } else if (this.forceProductionOnDevelopment && subdomain === 'external') {
        this.instanceType = 'external';
        this.api.setInstance('demo', false);
      }
    },
    dumpUsersDueToInvalidConfig() {
      this.users = [];
      this.currentUser = null;
      this.currentUserAuth = null;
    },
    saveSettings() {
      localStorage.setItem('settings', JSON.stringify(this.settings));
      invoke('toggle_auto_launch', {
        runOnStartup: this.settings.runOnStartup,
      }).catch((error: any) => {
        log.error("Couldn't toggle auto launch");
        log.error(error);
      });
    },
    async forgetCurrentUser() {
      if (this.currentUser) {
        log.debug(`Removing currentUser from localStorage: ${this.currentUser.id}`);
        localStorage.removeItem(`user_${this.currentUser.id}`);
        localStorage.removeItem(`auth_${this.currentUser.id}`);
        this.currentUser = null;
        this.currentUserAuth = null;
        log.debug('Starting api.logout()');
        await this.api.logout();
        log.debug('Successfully logged out');
      }
    },

    async updateUserInStorage(userChanges: { first_name: string; last_name: string; chat_nickname: string; timezone: string }) {
      if (!this.currentUser) {
        return;
      }
      this.currentUser.first_name = userChanges.first_name;
      this.currentUser.last_name = userChanges.last_name;
      this.currentUser.chat_nickname = userChanges.chat_nickname;
      this.currentUser.timezone = userChanges.timezone;

      localStorage.setItem(`user_${this.currentUser.id}`, JSON.stringify(this.currentUser));
    },

    async saveNewLoginUser(jwtResponse: GenericObject, subdomain: string) {
      this.currentUser = jwtResponse.user;
      this.currentUserAuth = {
        id: jwtResponse.user.id,
        instance: subdomain,
        accessToken: jwtResponse.access_token,
        refreshToken: jwtResponse.refresh_token,
        expiresAt: jwtResponse.expires_at,
        chatToken: jwtResponse.chat_token,
        version: this.authSettingsVersion,
      };
      localStorage.setItem(`user_${jwtResponse.user.id}`, JSON.stringify(this.currentUser));
      localStorage.setItem(`auth_${jwtResponse.user.id}`, JSON.stringify(this.currentUserAuth));
      this.api.setTokens(jwtResponse.access_token, jwtResponse.refresh_token, jwtResponse.expires_at);
    },
    async resetSettings() {
      this.settings = {
        theme: 'system',
        runOnStartup: true,
        showSystemNotifications: true,
        breakReminder: true,
        shiftReminderTime: 15,
        shiftAfterEndReminderTime: 15,
        emojiSkinTone: undefined,
      };
      this.saveSettings();
    },
    swapInstanceType() {
      if (this.instanceType === 'internal') {
        this.instanceType = 'external';
      } else {
        this.instanceType = 'internal';
      }
    },
    getAnnouncements() {
      this.api.getAnnouncements().then((response) => {
        this.announcements = response;
      });
    },
    addFavoriteChannel(channelId: string) {
      // check if the channel is already in the list
      if (this.favoriteChannels.find((channel) => channel.id === channelId)) {
        return;
      }
      this.favoriteChannels.push({
        id: channelId,
        order: this.favoriteChannels.length,
      });
      localStorage.setItem('favoriteChannels', JSON.stringify(this.favoriteChannels));
    },
    removeFavoriteChannel(channelId: string) {
      const index = this.favoriteChannels.findIndex((channel) => channel.id === channelId);
      if (index > -1) {
        this.favoriteChannels.splice(index, 1);
        localStorage.setItem('favoriteChannels', JSON.stringify(this.favoriteChannels));
      }
    },
    getFavoriteChannels() {
      const channels = localStorage.getItem('favoriteChannels');
      if (channels) {
        this.favoriteChannels = JSON.parse(channels);
      }
    },
    updateRecentlyUsedEmojis(unicode: string | null) {
      // Early return if unicode is null or undefined
      if (unicode === null || unicode === undefined) {
        console.warn('Attempted to update recently used emojis with null or undefined value.');
        return;
      }

      // Check if the emoji already exists in the array
      const existingIndex = this.recentlyUsedEmojis.findIndex((e) => e === unicode);

      if (existingIndex !== -1) {
        // Remove the emoji from its current position
        this.recentlyUsedEmojis.splice(existingIndex, 1);
      }

      // Add the emoji to the front of the array
      this.recentlyUsedEmojis.unshift(unicode);

      // Ensure the array doesn't exceed a reasonable size (e.g., 50 items)
      if (this.recentlyUsedEmojis.length > 50) {
        this.recentlyUsedEmojis.length = 50; // Truncate the array
      }

      // Save the updated array to localStorage
      localStorage.setItem('recentlyUsedEmojis', JSON.stringify(this.recentlyUsedEmojis));
    },

    loadRecentlyUsedEmojis() {
      const emojiString = localStorage.getItem('recentlyUsedEmojis');
      if (emojiString) {
        try {
          let emojis = JSON.parse(emojiString);

          // Ensure emojis is an array of strings
          if (!Array.isArray(emojis)) {
            console.warn('Invalid recently used emojis data format. Expected an array.');
            emojis = [];
          } else {
            // Filter out any non-string values
            emojis = emojis.filter((item) => typeof item === 'string');
          }

          this.recentlyUsedEmojis = emojis;
        } catch (error) {
          console.error('Failed to parse recently used emojis:', error);
          // In case of parsing error, reset to an empty array
          this.recentlyUsedEmojis = [];
        }
      } else {
        // If no data exists, initialize with an empty array
        this.recentlyUsedEmojis = [];
      }
    },
  },
  getters: {
    isInternalServer: (state) => state.instanceType === 'internal',
    isExternalServer: (state) => state.instanceType === 'external',
    isLoggedIn: (state) => state.currentUser !== null && !state.loggingIn,
    isDevUser: (state) => import.meta.env.MODE === 'development' || [3817, 24699, 15615, 23766].includes(state.currentUser?.id ?? -1),
    accessTokenExpired: (state) => state.currentUserAuth?.expiresAt && state.currentUserAuth.expiresAt < Date.now() / 1000,
    isDevelopmentEnvironment: () => import.meta.env.MODE === 'development',
    forceProductionOnDevelopment: () => import.meta.env.VITE_FORCE_PRODUCTION === 'true',
  },
});
