import BoopSound from '@sounds/boop.wav';
import BingBong from '@sounds/warn2.mp3';
import { withCatch } from '@utils/await-safe';
import { Logger } from '@utils/logger';
const log = new Logger('AUDIO_SERVICE');

class AudioService {
  private static instance: AudioService;
  private boopAudio: HTMLAudioElement;
  private bingBong: HTMLAudioElement;

  private constructor() {
    this.boopAudio = new Audio(BoopSound);
    this.bingBong = new Audio(BingBong);
  }

  public static getInstance(): AudioService {
    if (!AudioService.instance) {
      AudioService.instance = new AudioService();
    }
    return AudioService.instance;
  }

  async playBoopSound() {
    this.boopAudio.currentTime = 0;
    const [error] = await withCatch(this.boopAudio.play());
    if (error) {
      log.error('Failed to play boop sound');
      log.error(error);
    }
  }
  async playWarningSound() {
    this.bingBong.currentTime = 0;
    const [error] = await withCatch(this.bingBong.play());
    if (error) {
      log.error('Failed to play warning sound');
      log.error(error);
    }
  }
}

export default AudioService;
