<script setup lang="ts">
  import { computed } from 'vue';
  import { Message } from '@pubnub/chat';
  import { useChatStore } from '@src/store/chat';
  import { PhotoIcon } from '@heroicons/vue/16/solid';
  import { calculateTooltipPosition } from '@utils/chat/tooltip-calculator';
  import { KnownChatUser } from '@src/types/ChatAndMessaging';

  const props = defineProps<{
    parentMessage: { message: Message; avatar: string | null } | null;
    parentUser?: KnownChatUser;
  }>();

  const emits = defineEmits(['scroll-to-message', 'send-message']);

  const chatStore = useChatStore();

  const isMessageDeleted = computed(() => props.parentMessage?.message.deleted);

  const messageText = () => {
    if (isMessageDeleted.value) {
      return 'deleted message';
    }
    if (props.parentMessage?.message.text) {
      return props.parentMessage.message.text;
    }
    if (!props.parentMessage?.message.text && props.parentMessage?.message.files.length) {
      return 'Click to see attachment';
    }
    return '';
  };

  const handleMessageClick = () => {
    if (isMessageDeleted.value) {
      return;
    }
    emits('scroll-to-message', { message: props.parentMessage?.message });
  };

  const showUserTooltip = (event: MouseEvent, user?: KnownChatUser) => {
    /**
     * requestAnimationFrame is used to avoid a race condition when opening a new tooltip while another is still open.
     * Without this, the old tooltip might close but the new one may not display. This ensures the old tooltip is fully
     * closed before opening the new one.
     */

    requestAnimationFrame(() => {
      if (!user) {
        return;
      }

      chatStore.participantCardUser = user;
      chatStore.composerFocusStealOverride = true;
      chatStore.participantCardOpen = true;

      /**
       * We request an additional animation frame to ensure the tooltip is in the DOM,
       * allowing us to accurately retrieve its dimensions.
       */

      requestAnimationFrame(() => {
        let success = calculateTooltipPosition(event, 'chat-layer', 'participant-card');

        // If positioning the tooltip fails for any reason, close it and reset the values.
        if (!success) {
          chatStore.participantCardUser = null;
          chatStore.composerFocusStealOverride = false;
          chatStore.participantCardOpen = false;
        }
      });
    });
  };
</script>

<template>
  <div
    v-if="parentMessage && parentUser"
    class="before:group-hover/chat-message:border-surface-400/80 before:dark:group-hover/chat-message:border-surface-500 flex items-center gap-1 ml-14 mb-2 text-xs before:content-[''] before:absolute before:top-[0.5rem] before:right-[100%] before:left-9 before:h-5 before:w-8 before:border-surface-300 dark:before:border-surface-600 before:border-t-2 before:border-r-0 before:border-b-0 before:border-l-2 before:rounded-tl-lg"
  >
    <div class="shrink-0">
      <button class="shrink-0" @click="showUserTooltip($event, parentUser)">
        <img v-if="parentMessage.avatar" :src="parentMessage.avatar" class="pointer-events-none rounded-full size-5" draggable="false" alt="parent avatar" />
        <img
          v-else
          class="pointer-events-none rounded-full size-5"
          src="data:image/svg+xml;utf8,%3Csvg width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='64' height='64'%3E%3Cpath fill='%23ADC9FF' d='M0 0h64v64H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%23ADC9FF' d='M0 0h64v64H0z'/%3E%3Cpath d='m31.373 24.202 1.938-.764s12.206-4.545 20.985 2.524S105 70.66 105 70.66H35.786s-.391-.882-.954-2.4l.904-.56c7.44-4.612 10.797-11.653 11.242-21.131.395-8.41-4.386-15.475-13.54-21.228l-2.03-1.119-.035-.02zM25 26.656 14 31.5l11 4.652v-9.496z' fill='%2330308F'/%3E%3Cpath d='M27 25.776V37.76c2.377 2.405 3.733 5.942 4.067 10.61 0 7.824 1.205 13.701 3.615 17.63 6.752-4.185 9.877-10.538 10.299-19.525.294-6.277-2.709-11.884-9.01-16.82a2 2 0 1 1-2.246-1.637A53.995 53.995 0 0 0 28.68 25l.036.02-1.715.756z' fill='%2330308F'/%3E%3C/g%3E%3C/svg%3E"
          draggable="false"
          alt=""
        />
      </button>
    </div>
    <div class="shrink-0">
      <button class="shrink-0 font-semibold text-surface-600 hover:underline dark:text-surface-300" @click="showUserTooltip($event, parentUser)">
        {{ parentUser.name }}
      </button>
    </div>
    <div class="flex gap-1" :class="[isMessageDeleted ? '' : 'cursor-pointer']" @click="handleMessageClick">
      <div v-if="parentMessage.message.files.length < 1" class="text-surface-900 line-clamp-1 dark:text-surface-400" v-html="messageText()"></div>
      <div v-else class="shrink-0">
        <PhotoIcon class="size-4" />
      </div>
    </div>
  </div>
</template>
