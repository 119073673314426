<script setup lang="ts">
  import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
  import { EditorContent, useEditor } from '@tiptap/vue-3';
  import EmojiPicker from '@components/EmojiPicker/EmojiPicker.vue';

  import StarterKit from '@tiptap/starter-kit';
  import Underline from '@tiptap/extension-underline';
  import { Channel, MessageMentionedUsers } from '@pubnub/chat';
  // @ts-ignore
  import MentionSuggestion from './ChatMentionSuggestion';
  import { Mention } from '@tiptap/extension-mention';
  import CustomEnter from '@src/tiptap/tiptap-keyboard';
  import EmojiSuggestion from '@components/Chat/EmojiSuggestion';
  import CustomPasteHandler from '@components/Chat/CustomPasteExtension';
  import CustomCopyHandler from '@components/Chat/CustomCopyExtension';
  import { Markdown } from 'tiptap-markdown';
  import Placeholder from '@tiptap/extension-placeholder';
  import { XMarkIcon } from '@heroicons/vue/16/solid';
  import { PaperAirplaneIcon, PaperClipIcon } from '@heroicons/vue/24/solid';
  import ProgressBar from 'primevue/progressbar';

  // TODO: ADD FILE SUPPORT
  import { open } from '@tauri-apps/plugin-dialog';
  import { mimeTypes } from '@components/Chat/mimetypes';
  import { useChatStore } from '@src/store/chat';
  import { useAppStore } from '@src/store/app';
  import ChatFileAttachment from '@components/Chat/ChatFileAttachment.vue';
  import Dialog from 'primevue/dialog';
  import InputText from 'primevue/inputtext';
  import Button from 'primevue/button';
  import FormLabel from '@components/Shared/FormLabel.vue';
  import { DocumentTextIcon } from '@heroicons/vue/24/outline';
  import { throttle } from 'lodash';
  import { ModCodeBlock } from '@src/tiptap/tiptap-codeblock';
  import { useRouter } from 'vue-router';
  import { PendingChatMessage } from '@src/types/ChatAndMessaging';
  import { ZstdService as ZSTD } from '@src/services/zstd-service';
  import { Logger } from '@utils/logger';

  let zstd: ZSTD | undefined;
  const log = new Logger('MESSAGE_COMPOSER');
  const chatStore = useChatStore();
  const appStore = useAppStore();
  const router = useRouter();

  const emits = defineEmits(['send', 'share-editor', 'paste', 'attach']);

  const message = ref('');
  const showEmojiPicker = ref(false);
  const props = defineProps<{
    currentUserId: string;
    channelUrl: string;
  }>();
  const editingAttachment = ref<FileUploadDetails>();
  const isEditingAttachment = computed(() => editingAttachment.value !== undefined);
  const editingFilename = ref('');
  const messageToLong = ref(false);

  const composingChannelMessages = ref<Map<string, string>>(new Map());
  const fileAttachmentsContainer = ref<HTMLElement | null>();

  // TODO: FILE SUPPORT

  const allowed_extensions = mimeTypes.map((mimeType) => mimeType.extension);
  const largeEditorModeInterval = ref<NodeJS.Timeout | null>(null);

  watch(
    () => props.channelUrl,
    (newChannel: string, oldChannel: string) => {
      // save the current message and restore the old one, if available
      composingChannelMessages.value.set(oldChannel, message.value);
      message.value = composingChannelMessages.value.get(newChannel) ?? '';
      editor.value?.commands.setContent(message.value);
      // set cursor to back of editor
      editor.value?.commands.focus();
    }
  );

  const messageCharacterLength = computed(() => {
    return editor.value?.getText().length ?? 0;
  });

  const isLessThan400CharactersRemaining = computed(() => {
    return messageCharacterLength.value >= 3600;
  });

  const processAndSend = () => {
    if (!editor.value) {
      return;
    }
    // look for any nodes under this that have a type of mention
    // @ts-ignore
    const mentions = findMentionsRecursively(editor.value?.getJSON() ?? {}).map((mention) => {
      // @ts-ignore
      return { id: mention.id, name: mention.name };
    });
    // make mentions unique

    let mentionsObject: MessageMentionedUsers = {};

    mentions.forEach((mention: { id: string; name: string }, index: number) => {
      mentionsObject[index] = {
        id: mention.id,
        name: mention.name,
      };
    });

    // If no mentions, no message, or no attachments
    if (!mentions.length && !editor.value?.state.doc.textContent.trim().length && !attachments.value.length) {
      return;
    }

    if (messageCharacterLength.value > 4000) {
      messageToLong.value = true;
      return;
    }

    let composerState = chatStore.channelComposerStates.get(props.channelUrl);
    let replyToId = '';
    if (composerState?.replyingToMessage) {
      replyToId = composerState.replyingToMessage.timetoken;
    }

    if (!zstd) {
      log.error('zstd not initialized');
      return;
    }

    const encoder = new TextEncoder();
    const unit8Array: Uint8Array = encoder.encode(JSON.stringify(editor.value.getJSON()));
    let compressed: Uint8Array = zstd.compress(unit8Array);
    let numberArray: number[] = Array.from(compressed);
    let jsonCompressedMessageSchema = JSON.stringify(numberArray);

    let outgoingMessage: PendingChatMessage = {
      message: editor.value?.storage.markdown.getMarkdown(),
      messageSchema: jsonCompressedMessageSchema,
      type: 'text',
      mentions: mentionsObject ?? {},
      url: props.channelUrl,
      customType: null as string | null,
      parentId: replyToId ?? null,
    };

    if (chatStore.currentlyReplyingTo) {
      outgoingMessage.customType = 'reply';
      outgoingMessage.mentions[0] = {
        id: chatStore.currentlyReplyingTo.userId,
        name: replyingToUserName.value as string,
      };
      // outgoingMessage.mentions.push({
      //   id: chatStore.currentlyReplyingTo.userId,
      //   name: replyingToUserName.value,
      // });
    }

    emits('send', outgoingMessage);
    // clear the text input
    message.value = '';
    editor.value?.commands.setContent('');

    // dump reply to
    chatStore.stopReplyingToMessage(props.channelUrl);
  };

  MentionSuggestion.items = () => {
    return chatStore.currentChannelUsers;
  };

  /**
   * This is an html tree in JSON with elements, we need to crawl it to all depths to find a node type of mentions
   *
   * @param domTreeJson
   */
  const findMentionsRecursively = (domTreeJson: Record<string, any>) => {
    const mentions: any = [];
    const crawl = (node: Record<string, any>) => {
      if (node['type'] === 'mention') {
        mentions.push(node);
      }
      if (node['content']) {
        node['content'].forEach((child: object) => {
          crawl(child);
        });
      }
    };
    crawl(domTreeJson);
    return mentions.map((mention: any) => {
      return {
        id: mention.attrs.id.id,
        name: mention.attrs.id.name,
      };
    });
  };

  const placeHolder = computed(() => {
    // Cache the current channel URL and lookup the channel early
    const channelUrl = chatStore.currentChannelUrl;
    const channel = chatStore.knownChannels.get(channelUrl) as Channel;

    // Cache the channel ID and check early to reduce unnecessary processing
    const channelId = channel?.id;
    if (!channelId || (!channelId.startsWith('direct.') && !channelId.startsWith('group.'))) {
      return channel?.name ?? '...';
    }

    // Cache participants and check length early to avoid unnecessary computations
    const participantSet = chatStore.channelParticipantsMinimal.get(channelId);
    const participantCount = participantSet?.size ?? 0;

    if (!participantSet || participantSet.size === 0) {
      return '';
    }

    const participants = Array.from(participantSet.values());

    // If there's only one participant and it's the current user, return early
    if (participants.length === 1 && participants[0] === chatStore.currentUserId) {
      return chatStore.knownUsersByUuid.get(chatStore.currentUserId)?.name ?? '...';
    }

    // Filter out the current user if there are more participants
    const users = participants.filter((user) => user !== chatStore.currentUserId);

    const userNames = participants
      .filter((user) => user !== chatStore.currentUserId)
      .map((user) => chatStore.knownUsersByUuid.get(user)?.name ?? '...')
      .sort();

    return userNames.join(', ');
  });

  const fireTypingIndicator = throttle(() => {
    // TODO: something is racking up charges, and i'm not sure what. so temporarily removing this.
    // chatStore.currentChannelRaw?.startTyping().catch((error) => {
    //   console.error('Failed to start typing indicator', error);
    // });
  }, 1000);

  const editor = useEditor({
    content: '',
    onUpdate(props) {
      message.value = props.editor.getHTML();
      fireTypingIndicator();
    },
    editorProps: {
      attributes: {
        class: 'editor-content message',
        autocapitalize: 'off',
        autocomplete: 'off',
      },
    },
    extensions: [
      StarterKit.configure({
        codeBlock: false,
        code: {
          HTMLAttributes: {
            class: 'bg-surface-200 border border-surface-200',
          },
        },
        strike: {
          HTMLAttributes: {
            class: 'line-through',
          },
        },
        heading: {
          levels: [1, 2, 3, 4, 5, 6],
        },
      }),
      ModCodeBlock.configure({
        languageClassPrefix: 'language-',
        exitOnTripleEnter: true,
        exitOnArrowDown: true,
        HTMLAttributes: {
          class: 'code-block',
        },
      }),
      Markdown.configure({
        breaks: true,
      }),
      Underline.configure({
        HTMLAttributes: {
          class: 'underline',
        },
      }),
      Placeholder.configure({
        placeholder: ({ editor }) => {
          return placeHolder.value;
        },

        emptyEditorClass: 'is-editor-empty',
      }),
      Mention.configure({
        HTMLAttributes: {
          class: 'mention',
        },
        deleteTriggerWithBackspace: true,
        renderHTML({ options, node }) {
          let id = '';
          let name = '';
          if (typeof node.attrs.id === 'object') {
            id = node.attrs.id.id;
            name = node.attrs.id.name;
          } else {
            id = node.attrs.id;
            name = node.attrs.label;
          }
          return [
            'span',
            {
              class: 'mention',
              'data-type': 'mention',
              'data-id': id,
              'data-name': name,
            },
            '@' + name,
          ];
        },
        suggestion: MentionSuggestion,
      }),

      EmojiSuggestion,
      CustomPasteHandler,
      CustomCopyHandler,
      CustomEnter.configure({
        onEnter: processAndSend,
      }),
    ],
  });

  async function openDialog(): Promise<void> {
    const selectedFiles = await open({
      multiple: true,
      title: 'Upload files',
      filters: [
        {
          name: 'Image',
          extensions: allowed_extensions,
        },
      ],
    });
    if (!selectedFiles || selectedFiles.length < 1) return;

    emits('attach', selectedFiles);
  }
  const toggleEmojiPicker = () => {
    calculateEmojiPickerDistanceFromBottom();
    chatStore.composerFocusStealOverride = showEmojiPicker.value = !showEmojiPicker.value;
  };
  const msgComposerContainer = ref<HTMLElement>();

  const calculateEmojiPickerDistanceFromBottom = () => {
    if (!msgComposerContainer.value) {
      emojiPickerPosition.value.bottom = '32px';
      return;
    }
    emojiPickerPosition.value.bottom = msgComposerContainer.value.offsetHeight + 'px';
  };

  const emojiPickerPosition = ref({
    bottom: '16px',
    right: '20px',
  });

  const unicodeToEmoji = (unicode: string) => {
    const codePoints = unicode.split('-').map((u) => parseInt(u, 16));
    return String.fromCodePoint(...codePoints);
  };

  const handleEmojiSelect = (emoji: Emoji) => {
    showEmojiPicker.value = false;
    chatStore.composerFocusStealOverride = false;

    const skin_variation_from_base = appStore.settings.emojiSkinTone;

    if (!emoji.skin_variations || !skin_variation_from_base) {
      appStore.updateRecentlyUsedEmojis(emoji.unified);
      editor.value?.commands.insertContent(unicodeToEmoji(emoji.unified));
      editor.value?.commands.focus();
      return;
    }

    let variation: string = emoji.skin_variations[skin_variation_from_base].unified;
    appStore.updateRecentlyUsedEmojis(variation);
    editor.value?.commands.insertContent(unicodeToEmoji(variation));
    editor.value?.commands.focus();
  };

  const emojiAway = () => {
    showEmojiPicker.value = false;
    chatStore.composerFocusStealOverride = false;
    editor.value?.commands.focus();
  };

  const stopEditAndReply = (channelUrl: string) => {
    chatStore.stopReplyingToMessage(channelUrl);
    chatStore.stopEditingMessage(channelUrl);
    //Clear the message ref because the editor doesn't do it for you other wise you will have weird behavior with mentions
    message.value = '';
    // remove all content from the editor
    editor.value?.commands.clearContent();
  };

  const replyingToUserName = computed(() => {
    if (chatStore.currentlyReplyingTo) {
      return chatStore.knownUsersByUuid.get(chatStore.currentlyReplyingTo.userId)?.name;
    }
    return '';
  });

  const deleteAttachment = (fileDetails: FileUploadDetails) => {
    let attachments = chatStore.channelAttachments.get(props.channelUrl);

    if (!attachments) {
      return;
    }

    for (let i = 0; i < attachments.length; i++) {
      if (attachments[i].file.name === fileDetails.file.name) {
        attachments.splice(i, 1);
        chatStore.channelAttachments.set(props.channelUrl, attachments);
        break;
      }
    }
  };

  const isChatFocused = ref(false);

  const handleKeyDown = (e: KeyboardEvent) => {
    const currentRouteName = router.currentRoute.value.name;
    if (currentRouteName !== 'chat') return;

    if (e.key === 'Escape') {
      chatStore.channelAttachments.set(props.channelUrl, []);
      return;
    }

    if (e.metaKey || e.ctrlKey) return;

    const ignoredKeys = new Set([
      'Alt',
      'Shift',
      'Tab',
      'Delete',
      'Escape',
      'Backspace',
      'ArrowUp',
      'ArrowDown',
      'ArrowLeft',
      'ArrowRight',
      'Enter',
      'Home',
      'Insert',
      'CapsLock',
      'PageUp',
      'PageDown',
      'End',
      'ContextMenu',
      'ScrollLock',
      'Pause',
      'PrintScreen',
      'NumLock',
      'F1',
      'F2',
      'F3',
      'F4',
      'F5',
      'F6',
      'F7',
      'F8',
      'F9',
      'F10',
      'F11',
      'F12',
    ]);

    if (!isChatFocused.value && !chatStore.composerFocusStealOverride && !editor.value?.isFocused) {
      editor.value?.commands.focus();

      if (!ignoredKeys.has(e.key)) {
        isChatFocused.value = true;
        editor.value?.commands.insertContent(e.key);
      }

      if (e.key === 'Backspace') {
        isChatFocused.value = true;
        e.preventDefault();
        editor.value?.commands.deleteRange({
          from: editor.value.state.selection.from - 1,
          to: editor.value.state.selection.from,
        });
      }
    }
  };

  const attachments = computed(() => chatStore.channelAttachments.get(props.channelUrl) ?? []);

  function cloneFileWithNewName(originalFile: File, newName: string): Promise<File> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (event: any) {
        const clonedFile: File = new File([event.target.result], newName, {
          type: originalFile.type,
          lastModified: originalFile.lastModified,
        });
        resolve(clonedFile);
      };

      reader.onerror = function (error) {
        reject(error);
      };

      reader.readAsArrayBuffer(originalFile);
    });
  }

  const stopEditingFile = () => {
    editingAttachment.value = undefined;
    editingFilename.value = '';
  };

  const saveFileEdit = async () => {
    if (!editingAttachment.value || !editingFilename.value) {
      return;
    }
    const attachments = chatStore.channelAttachments.get(props.channelUrl) ?? [];
    let attachmentIndex = attachments.findIndex((attachment) => attachment.file.name === editingAttachment.value?.file.name);
    if (attachmentIndex === -1) {
      return;
    }

    attachments[attachmentIndex].file = await cloneFileWithNewName(editingAttachment.value.file, editingFilename.value);
    chatStore.channelAttachments.set(props.channelUrl, attachments);

    stopEditingFile();
  };

  const handleChatFileEdit = (fileDetails: FileUploadDetails) => {
    chatStore.composerFocusStealOverride = true;
    editingAttachment.value = attachments.value.find((attachment) => attachment.file.name === fileDetails.file.name) ?? undefined;
    editingFilename.value = fileDetails.file.name;
  };

  const handleComposerResizeEvent = () => {
    // if the editor gets larger than 100px tall, we need to tell the state we're in thicc mode
    if (msgComposerContainer.value?.offsetHeight && msgComposerContainer.value.offsetHeight > 100) {
      // clear the timeout
      if (largeEditorModeInterval.value) {
        clearInterval(largeEditorModeInterval.value);
      }
      chatStore.largeEditorMode = true;
    } else {
      setTimeout(() => {
        chatStore.largeEditorMode = false;
      }, 2000);
    }
  };

  const attachResizeObserverToComposer = () => {
    const composer = msgComposerContainer.value;
    if (!composer) {
      return;
    }
    const resizeObserver = new ResizeObserver(handleComposerResizeEvent);
    resizeObserver.observe(composer);
  };

  const removeResizeObserverFromComposer = () => {
    const composer = msgComposerContainer.value;
    if (!composer) {
      return;
    }
    const resizeObserver = new ResizeObserver(handleComposerResizeEvent);
    resizeObserver.unobserve(composer);
  };

  const handleEditorBlur = () => {
    isChatFocused.value = false;
  };

  const closeAttachmentEditor = () => {
    editingAttachment.value = undefined;
    chatStore.composerFocusStealOverride = false;
    editor.value?.commands.focus();
  };

  onMounted(async () => {
    zstd = await ZSTD.getInstance();
    document.addEventListener('keydown', handleKeyDown);
    editor.value?.view.dom.addEventListener('blur', handleEditorBlur);
    emits('share-editor', editor.value);
    attachResizeObserverToComposer();
  });

  onBeforeUnmount(() => {
    document.removeEventListener('keydown', handleKeyDown);
    editor.value?.view.dom.removeEventListener('blur', handleEditorBlur);
    removeResizeObserverFromComposer();
  });
</script>

<template>
  <div ref="msgComposerContainer" class="pt-4 bg-white dark:bg-surface-800" style="min-height: 60px">
    <Dialog v-if="messageToLong" class="w-[450px]" :visible="messageToLong" modal>
      <template #container>
        <div class="select-none">
          <div class="flex flex-col items-center justify-center px-8 py-6 text-center">
            <div class="uppercase font-semibold mb-4 text-surface-700">Oops! Your Message Is a Bit Too Long.</div>
            <div class="text-surface-500 font-light">
              To keep things fair for everyone, please adjust your message of
              {{ messageCharacterLength }} to fit within the 4000-character limit.
            </div>
          </div>
          <div class="bg-surface-100">
            <div class="p-4">
              <Button label="Okay" class="w-full text-sm" @click="messageToLong = false" />
            </div>
          </div>
        </div>
      </template>
    </Dialog>
    <Dialog v-if="editingAttachment" id="file-edit" class="w-2/5" :visible="isEditingAttachment" :show="true" modal @close="closeAttachmentEditor">
      <template #container>
        <div class="flex flex-col">
          <div class="absolute -top-14 left-4">
            <img v-if="editingAttachment?.preview" :src="editingAttachment?.preview" class="object-contain h-44 w-44" alt="avatar" />
            <DocumentTextIcon v-else class="size-36 fill-white stoke-1 dark:fill-surface-800 dark:text-surface-500" />
          </div>
          <div class="p-4">
            <div class="mt-24 text-xl font-bold dark:text-surface-200">
              {{ editingAttachment?.file.name }}
            </div>
            <div class="mt-4">
              <FormLabel>File Name</FormLabel>
              <InputText v-model="editingFilename" class="w-full" @keydown.enter="saveFileEdit" />
            </div>
          </div>
          <div class="p-4 flex justify-end gap-x-2">
            <Button label="Cancel" severity="secondary" @click="stopEditingFile" />
            <Button label="Save" @click="saveFileEdit" />
          </div>
        </div>
      </template>
    </Dialog>
    <div id="composer-container" class="mx-4">
      <div v-if="attachments.length || chatStore.optimizingUploads" ref="fileAttachmentsContainer" class="bg-surface-100 dark:bg-surface-700 rounded-t-lg p-4 text-xs text-surface-500 dark:text-surface-400 border-b dark:border-surface-600">
        <div v-if="chatStore.optimizingUploads">
          <p class="text-center mb-1">Optimizing Uploads</p>
          <ProgressBar mode="indeterminate" style="height: 4px"></ProgressBar>
        </div>
        <div class="flex gap-8 pb-2 overflow-auto small-scroll">
          <ChatFileAttachment v-for="(fileDetails, index) in attachments" :key="`attachment-${channelUrl}-${index}`" class="shrink-0" :file-details="fileDetails" @delete="deleteAttachment(fileDetails)" @edit="handleChatFileEdit(fileDetails)" />
        </div>
      </div>
      <div v-if="chatStore.currentlyReplyingTo" class="bg-surface-100 dark:bg-surface-900/50 rounded-t-lg p-2 text-xs text-surface-500 dark:text-surface-400 flex items-center h-10">
        <div class="flex gap-1 grow">
          <span>Replying to</span><span class="font-semibold text-surface-900 dark:text-surface-200">{{ replyingToUserName }}</span>
        </div>
        <div class="flex items-center">
          <button class="ml-2 bg-surface-900 dark:bg-surface-500 rounded-full" @click="stopEditAndReply(channelUrl)">
            <XMarkIcon class="size-5 p-0.5 text-surface-100 dark:text-surface-900" />
          </button>
        </div>
      </div>
      <div v-if="chatStore.currentlyEditingMessage">
        <div class="bg-surface-100 dark:bg-surface-900/50 rounded-t-lg p-2 text-xs text-surface-500 dark:text-surface-400 flex items-center h-10">
          <div class="flex gap-1 grow">
            <span>Editing Message</span>
          </div>
          <div class="flex items-center">
            <button class="ml-2" @click="stopEditAndReply(channelUrl)">
              <XMarkIcon class="size-4 text-surface-500 dark:text-surface-200" />
            </button>
          </div>
        </div>
      </div>
      <div class="flex items-center bg-surface-200/60 dark:bg-surface-700" :class="[chatStore.currentlyReplyingTo || chatStore.currentlyEditingMessage || attachments.length ? 'rounded-b-md' : 'rounded-md']">
        <EmojiPicker v-if="showEmojiPicker" id="emojiPicker" class="absolute z-10" :style="emojiPickerPosition" @emoji-selected="handleEmojiSelect" @close-picker="emojiAway" />
        <div class="relative w-full flex">
          <div class="absolute top-2 left-2 w-7 flex z-10">
            <button @click="openDialog">
              <PaperClipIcon class="size-6 text-surface-500 dark:hover:text-surface-100" />
            </button>
          </div>
          <div v-if="isLessThan400CharactersRemaining" class="absolute bottom-2 right-2 border" :class="[4000 - messageCharacterLength < 0 ? 'text-red-500' : '']">
            {{ 4000 - messageCharacterLength }}
          </div>
          <editor-content id="message-editor" v-model="message" :editor="editor" class="w-full small-scroll overflow-y-auto max-h-[23rem]" autocapitalize="off" @keydown.esc="stopEditAndReply(channelUrl)" />
          <div>
            <div class="flex justify-end absolute top-2 right-2 gap-x-2 w-16">
              <button @click="toggleEmojiPicker">
                <svg class="size-6 group" viewBox="0 0 100 100">
                  <circle cx="50" cy="50" r="45" :class="[showEmojiPicker ? 'fill-[#ffcc4d]' : 'fill-[rgb(113,113,112)] group-hover:fill-[#ffcc4d]']" />
                  <circle cx="35" cy="40" r="6" :class="[showEmojiPicker ? 'fill-black' : 'fill-white dark:fill-black group-hover:fill-black']" />
                  <circle cx="65" cy="40" r="6" :class="[showEmojiPicker ? 'fill-black' : 'fill-white dark:fill-black group-hover:fill-black']" />
                  <path d="M 30 60 Q 50 80 70 60" stroke="currentColor" stroke-width="6" fill="none" :class="[showEmojiPicker ? 'stroke-black' : 'stroke-white dark:stroke-black group-hover:stroke-black']" />
                </svg>
              </button>

              <button @click="processAndSend">
                <PaperAirplaneIcon class="size-6 text-surface-500 dark:hover:text-surface-100" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
