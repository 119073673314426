<script setup lang="ts">
  import InputText from 'primevue/inputtext';
  import { User as ChatUser } from '@pubnub/chat';
  import { computed, onMounted, ref } from 'vue';
  import { useChatStore } from '@src/store/chat';
  import { PencilIcon } from '@heroicons/vue/16/solid';
  import { calculateTooltipPosition } from '@utils/chat/tooltip-calculator';

  const emits = defineEmits(['close', 'send-message']);
  const chatStore = useChatStore();
  const message = ref('');
  const msgInput = ref<null | InstanceType<typeof InputText>>(null);
  const editingStatus = ref(false);
  const customStatusMessage = ref();
  const customStatusInput = ref<null | InstanceType<typeof InputText>>(null);

  const user = computed((): ChatUser | null => {
    return chatStore.participantCardUser as ChatUser | null;
  });

  const isOnline = computed(() => {
    if (!user.value) {
      return false;
    }
    return chatStore.onlineUserIds.has(user.value.id);
  });

  const isIdle = computed(() => {
    if (!user.value) {
      return false;
    }
    return chatStore.idleUserIds.has(user.value.id);
  });

  const getInitials = (name: string | undefined) => {
    if (!name) return '';
    return name
      .split(' ')
      .map((word) => word[0])
      .join('')
      .slice(0, 2);
  };

  const groupAndTeams = (user: ChatUser): string => {
    let group: string = (user.custom?.group as string) || '';
    let teams: string = (user.custom?.team_names as string) || '';

    if (group.endsWith(',')) {
      group = group.slice(0, -1);
    }
    if (teams.endsWith(',')) {
      teams = teams.slice(0, -1);
    }

    return [group, teams].filter(Boolean).join(' ');
  };

  const closeTooltip = () => {
    emits('close');
  };
  const sendMessage = () => {
    emits('send-message', { user: user.value, message: message.value });
    closeTooltip();
  };

  const startEditing = () => {
    editingStatus.value = true;
    requestAnimationFrame(() => {
      customStatusInput.value?.$el?.focus();
    });
  };

  const saveEdit = () => {
    const status = customStatusMessage.value || '';
    chatStore.setMyStatus(status);
    editingStatus.value = false;
  };

  const resetStatusToolTip = () => {
    chatStore.userStatusTooltipMessage = null;
    chatStore.showUserStatusTooltip = false;
  };

  const showStatusTooltip = (event: MouseEvent, status: 'Online' | 'Idle' | 'Offline') => {
    requestAnimationFrame(() => {
      chatStore.userStatusTooltipMessage = status;
      chatStore.showUserStatusTooltip = true;

      requestAnimationFrame(() => {
        let success = calculateTooltipPosition(event, 'chat-layer2', 'user-status', 'right');
        // If positioning the tooltip fails for any reason, close it and reset the values.
        if (!success) {
          resetStatusToolTip();
        }
      });
    });
  };

  onMounted(() => {
    requestAnimationFrame(() => {
      msgInput.value?.$el?.focus();
    });
  });
</script>

<template>
  <div v-if="user" class="bg-white w-72 text-black shadow-md dark:bg-surface-800 ring-[4px] ring-surface-300 dark:ring-surface-950 dark:shadow-none rounded-md select-auto">
    <div class="w-full h-full z-20">
      <div v-if="user.profileUrl" class="bg-cover bg-center h-24" :style="`background-image: url('${user.profileUrl}')`"></div>
      <div v-else class="bg-surface-200 dark:bg-surface-900 h-24"></div>
      <div class="p-4 space-y-4 -mt-16">
        <div class="relative w-fit">
          <img v-if="user.profileUrl" class="relative w-20 h-20 rounded-full ring-[6px] ring-white dark:ring-surface-950 object-cover select-none shrink-0" draggable="false" :src="user.profileUrl" alt="" />
          <div v-else class="bg-surface-200 w-20 h-20 ring-6 ring-[6px] ring-white dark:ring-surface-950 dark:bg-surface-700 rounded-full shrink-0 relative flex items-center text-2xl justify-center dark:text-surface-200">
            {{ getInitials(user.name) }}
          </div>
          <div v-if="isOnline" class="absolute bottom-0 rounded-full border-2 bg-green-500 size-6 -right-0.5 dark:border-surface-950" @mouseleave="resetStatusToolTip" @mouseenter="showStatusTooltip($event, 'Online')"></div>
          <div v-if="isIdle" class="absolute bottom-0 rounded-full border-2 bg-yellow-500 size-6 -right-0.5 dark:border-surface-950" @mouseleave="resetStatusToolTip" @mouseenter="showStatusTooltip($event, 'Idle')"></div>
          <div v-if="!isOnline && !isIdle" class="absolute bottom-0 rounded-full border-2 bg-surface-500 size-6 -right-0.5 dark:border-surface-950" @mouseleave="resetStatusToolTip" @mouseenter="showStatusTooltip($event, 'Offline')"></div>
        </div>
        <div class="p-1 text-left">
          <div class="line-clamp-1 capitalize text-lg font-semibold dark:text-surface-300">
            {{ user.name?.toLowerCase() }}
          </div>
          <div class="text-xs dark:text-surface-400">
            {{ groupAndTeams(user) }}
          </div>
          <div v-if="user.id === chatStore.currentUserId" class="mt-1">
            <span class="text-xs text-gray-500 dark:text-surface-400 flex items-center">Your Status <PencilIcon class="ml-1 w-4 h-4 text-gray-500 dark:text-surface-400 cursor-pointer" @click="startEditing" /></span>
            <div v-if="!editingStatus" class="text-xs text-gray-500 dark:text-surface-400 mt-1">
              <span>{{ chatStore.getUserStatus(user.id) }}</span>
            </div>
            <div v-if="editingStatus">
              <InputText ref="customStatusInput" v-model="customStatusMessage" size="small" class="w-full text-sm" autofocus />
              <div class="flex justify-end mt-1">
                <button class="text-xs text-gray-500 dark:text-surface-400" @click="editingStatus = false">Cancel</button>
                <button class="text-xs text-primary-500 dark:text-primary-400 ml-2" @click="saveEdit">Save</button>
              </div>
            </div>
          </div>
          <div v-else-if="chatStore.getUserStatus(user.id)">
            <span class="text-xs text-gray-500 dark:text-surface-400">{{ chatStore.getUserStatus(user.id) }}</span>
          </div>
        </div>
        <div v-if="user.id !== chatStore.currentUserId">
          <InputText ref="msgInput" v-model="message" size="large" :placeholder="`Message @${user.name}`" class="w-full text-sm" @keydown.enter="sendMessage" @keydown.esc="closeTooltip()" />
        </div>
        <div class="absolute top-0 right-4 border border-surface-300 bg-white/80 shadow-md dark:shadow-none text-surface-900 flex items-center text-xs font-semibold px-2 py-1">
          {{ user.id.replace('mytimein-user-', '') }}
        </div>
      </div>
    </div>
  </div>
</template>
